import React, { useState } from "react";
import KeywordSearchTerm from "./lib/keyword-searchterm-targeting";
import NegativeKeywordTargeting from "./lib/negative-keyword-targeting";

const KeywordTargeting = () => {
  const [mode, setMode] = useState("keyword-targeting");
  return (
    <div className="container-fluid" id="kt_content_container">
      <div className="row mb-8 px-0 mx-0">
        <div
          className="col-12 px-0 mx-0"
          style={{ borderBottom: "1px solid #e6332a" }}
        >
          <button
            className="btn btn-sm mx-2 px-7 fs-6"
            style={{
              background: "#e6332a0f",
              borderRadius: " 20px 20px 0px 0px",
              color: "rgb(230, 51, 42)",
              borderColor:
                mode === "keyword-targeting"
                  ? "#e6332ac2"
                  : "rgba(230, 51, 42, 0.09)",
              borderStyle: "solid",
              borderWidth: "1px 1px 0px 1px",
            }}
            onClick={() => {
              setMode("keyword-targeting");
            }}
          >
            Search term as keyword
          </button>
          <button
            className="btn btn-sm mx-2 px-7 fs-6"
            style={{
              background: "#e6332a0f",
              borderRadius: "20px 20px 0px 0px",
              color: "rgb(230, 51, 42)",
              borderColor:
                mode === "negative-keyword-targeting"
                  ? "#e6332ac2"
                  : "rgba(230, 51, 42, 0.09)",
              borderStyle: "solid",
              borderWidth: "1px 1px 0px 1px",
            }}
            onClick={() => {
              setMode("negative-keyword-targeting");
            }}
          >
            Negative keyword targeting
          </button>
        </div>
      </div>
      <div className="row">
        {mode === "keyword-targeting" && <KeywordSearchTerm />}
      </div>
      <div className="row">
        {mode === "negative-keyword-targeting" && <NegativeKeywordTargeting />}
      </div>
    </div>
  );
};

export default KeywordTargeting;
