import { Table, message } from "antd";
import React, { useContext, useEffect, useState } from "react";
import AdList from "./adList";
import { useLocation, useNavigate } from "react-router-dom";
import {
  DisplayNotification,
  getTableLoading,
  urlDecode,
} from "../../../../config";
import { GlobalContext } from "../../../../commonContext";
import { MakeApiCall } from "../../../../apis/make-api-call";
import NoDataTable from "../../../../component/no-data-table";
import EsfTable from "../../../../component/esf-table";
import LwaMarketplaceModal from "./lwa-marketplace-modal";
const AdsApi = (props) => {
  const { getList, list, loading, cred } = props;
  const contextValue = useContext(GlobalContext);
  const location = useLocation();
  const navigate = useNavigate();
  const [adListModal, setAdListModal] = useState(false);
  const [adList, setAdList] = useState();
  const [isOpenLwaModal, setIsOpenLwaModal] = useState(false);

  // useEffect(() => {
  //   const newAdvClientId = cred?.advertising_client_id;
  //   window.onAmazonLoginReady = function () {
  //     window.amazon.Login.setClientId(
  //     newAdvClientId
  //     );
  //   };
  //   let a = document.createElement("script");
  //   a.type = "text/javascript";
  //   a.async = true;
  //   a.id = "amazon-login-sdk";
  //   a.src = "https://assets.loginwithamazon.com/sdk/na/login1.js";
  //   const getRoot = document.getElementById("amazon-root");
  //   getRoot.appendChild(a);

  //   return () => {};
  // }, []);

  const GenerateAdRefreshToekn = async (data) => {
    const response = await MakeApiCall(
      `user-credential/generate-refresh-ad-token`,
      "post",
      data,
      true
    );
    if (response?.status) {
      message.destroy();
      localStorage.removeItem("adCode");
      setAdList(JSON.parse(response?.data));
      setAdListModal(true);
      DisplayNotification("success", "Success", response?.message);
    } else {
      message.destroy();
      DisplayNotification("error", "Error", response?.message);
    }
  };

  useEffect(() => {
    if (location.pathname.includes("/callbackads")) {
      // return console.log(urlDecode(location), "location");
      if (location?.search) {
        const { code, marketplace_id, seller_name } = urlDecode(location);
        if (!code) {
          getList("Advertising-API");
          return;
        }
        localStorage.setItem("adCode", code);
        navigate(location?.pathname);
        message.destroy();
        message.loading("Loading...", 0);
        GenerateAdRefreshToekn({
          ad_code: localStorage.getItem("adCode"),
          marketplace_id,
          seller_name,
        });
      } else {
        getList("Advertising-API");
      }
    }
  }, [location]);

  const columns = [
    {
      title: "Seller Account Name",

      render: (record) =>
        JSON.parse(record.credential_details)?.seller_name || "-",
    },
    {
      title: "Profile ID",

      render: (record) =>
        JSON.parse(record.credential_details)?.profile_id || "-",
    },
    {
      title: "Client ID",

      render: (record) =>
        JSON.parse(record.credential_details)?.client_id || "-",
    },
    {
      title: "Country Code",

      render: (record) =>
        JSON.parse(record.credential_details)?.country_code || "-",
    },
    {
      title: "Currency Code",

      render: (record) =>
        JSON.parse(record.credential_details)?.currency_code || "-",
    },
    {
      title: "Time Zone",

      render: (record) =>
        JSON.parse(record.credential_details)?.time_zone || "-",
    },
  ];

  return (
    <div className="row gy-5 g-xl-5">
      <div className="col-xxl-12">
        <div className="card card-xxl-stretch mb-5 mb-xl-8">
          <div className="card-header border-bottom-dashed">
            <h3 className="card-title align-items-start flex-column">
              <span className="card-label fw-bolder text-dark">
                Amazon Advertising Credentials List
              </span>
            </h3>
            <div className="card-toolbar">
              <button
                className="btn btn-warning fs-7 fw-bolder text-dark"
                onClick={() => {
                  setIsOpenLwaModal(true);
                }}
              >
                <i className="fab fa-amazon text-dark fs-2" /> Login with Amazon
              </button>
            </div>
          </div>
          <div className="card-body py-2">
            <div className="table-responsive">
              {loading ? (
                <NoDataTable columns={columns} type="loading" />
              ) : list?.length > 0 ? (
                <EsfTable
                  columns={columns?.map((d) => ({
                    ...d,
                    width: d?.title?.length * 13,
                  }))}
                  sticky={{
                    offsetHeader: "10px",
                  }}
                  scroll={{
                    x: "max-content",
                    y: "calc(100vh - 430px)",
                  }}
                  fixed="top"
                  dataSource={list}
                  rowKey="key"
                  loading={loading}
                  pagination={false}
                />
              ) : (
                <>
                  <NoDataTable columns={columns} type="no-data" />
                </>
              )}
            </div>
          </div>
        </div>
      </div>
      <div id="amazon-root" />
      {adListModal && (
        <AdList
          show={adListModal}
          data={adList?.map((d, i) => {
            return { ...d, key: i };
          })}
          advertisingList={list}
          onHide={() => {
            setAdList([]);
            setAdListModal(false);
          }}
          {...props}
        />
      )}
      {isOpenLwaModal && (
        <LwaMarketplaceModal
          show={isOpenLwaModal}
          onHide={() => {
            setIsOpenLwaModal(false);
          }}
          cred={cred}
          contextValue={contextValue}
        />
      )}
    </div>
  );
};

export default AdsApi;
