import React, { Suspense } from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
// import Page from "./modules/pages";
import Login from "./modules/pages/auth/index";
import PageIndex from "./modules/pages";
// import SignUp from "./modules/pages/auth/lib/sign-up";

function AppRouter() {
  return (
    // basename="/egas"
    <BrowserRouter>
      <Routes>
        <Route path="/login" element={<Login />} />
        {/* <Route path="/sign-up" component={SignUp} exact /> */}
        <Route path="/" element={<PageIndex />} />
        <Route path="*" element={<PageIndex />} />
      </Routes>
    </BrowserRouter>
  );
}

export default AppRouter;
