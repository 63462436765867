import React, { useContext, useEffect, useState } from "react";
import DaypartingConfiguration from "./lib/table-config";
import {
  ConvertParams,
  DefaultPerPage,
  DisplayNotification,
  GetMarketplaceDropdown,
  GetMarketplaceList,
} from "../../../config";
import { MakeApiCall } from "../../../apis/make-api-call";
import { Select, Switch } from "antd";
import NoDataTable from "../../../component/no-data-table";
import EsfTable from "../../../component/esf-table";
import { GlobalContext } from "../../../commonContext";
import CampaignModal from "./lib/campaign-modal";
import Pagination from "../../../component/esf-table/lib/pagination";

const Dayparting = () => {
  const contextValue = useContext(GlobalContext);
  const [isOpenDaypartingConfig, setIsOpenDaypartingConfig] = useState(false);
  const [loading, setLoading] = useState(true);
  const [list, setList] = useState([]);
  const [updateData, setUpdateData] = useState({});
  const [flag, setFlag] = useState("create");
  const [selectedCampaign, setSelectedCampaign] = useState([]);
  const [campaignView, setCampaignView] = useState(false);
  const [allCampaign, setAllCampaign] = useState([]);
  const [totalPage, setTotalPage] = useState(1);
  const [selected_marketplace, set_selected_marketplace] = useState(null);
  const [marketplace_list, set_marketplace_list] = useState([]);
  const [currency_list, set_currency_list] = useState({});
  const [selected_currency_sign, set_selected_currency_sign] = useState("");
  const [all_filters, set_all_filters] = useState({
    page: 1,
    "per-page": DefaultPerPage,
    marketplace_id: null,
  });

  useEffect(() => {
    GetMarketplace();
  }, [contextValue?.data?.selected_sub_user]);

  const GetMarketplace = async () => {
    const marketplace = await GetMarketplaceDropdown();
    if (marketplace?.marketplace_list?.length > 0) {
      set_marketplace_list(marketplace?.marketplace_list);
      set_selected_marketplace(
        marketplace?.marketplace_list[0]?.marketplace_id
      );
      set_currency_list(marketplace?.currency_list);
      set_selected_currency_sign(
        marketplace?.currency_list[
          marketplace?.marketplace_list[0]?.marketplace_id
        ]
      );
      set_all_filters({
        ...all_filters,
        marketplace_id: marketplace?.marketplace_list[0]?.marketplace_id,
      });
      GetAllCampaign(marketplace?.marketplace_list[0]?.marketplace_id);
      GetDaypartingList({
        ...all_filters,
        marketplace_id: marketplace?.marketplace_list[0]?.marketplace_id,
      });
    }
  };

  const GetDaypartingList = async (filters) => {
    const response = await MakeApiCall(
      `day-parting/get-day-parting-list${ConvertParams(filters)}`,
      `GET`,
      null,
      true
    );
    if (response?.status) {
      setLoading(false);
      setList(response?.data?.records);
      setTotalPage(response?.data?.pagination?.totalCount);
    } else {
      setLoading(false);
      DisplayNotification("error", "Error", response?.message);
    }
  };

  const GetAllCampaign = async (marketplace_id) => {
    const response = await MakeApiCall(
      `advertising/get-all-campaigns?marketplace_id=${marketplace_id}`,
      `GET`,
      null,
      true
    );
    if (response?.status) {
      setAllCampaign(response?.data);
    } else {
      DisplayNotification("error", "Error", response?.message);
    }
  };

  const UpdateDaypartingStatus = async (dayStatus, id) => {
    const response = await MakeApiCall(
      `day-parting/update-status/${id}`,
      `PUT`,
      { status: dayStatus },
      true
    );
    if (response?.status) {
      setLoading(true);
      GetDaypartingList(all_filters);
    } else {
      setLoading(false);
      DisplayNotification("error", "Error", response?.message);
    }
  };

  const columns = [
    {
      title: "Profile Name",
      dataIndex: "name",
      key: "name",
      render: (text) => <label className="fw-bold ">{text}</label>,
    },
    {
      title: "Campaigns",
      dataIndex: "campaign_list",
      key: "campaign_list",
      render: (text) => (
        <div className="d-flex align-items-center">
          {" "}
          <label className="fw-bold me-2 ">{text?.length}</label>
          <span
            className="btn btn-icon bg-light btn-active-color-primary btn-sm"
            onClick={() => {
              setSelectedCampaign(text);
              setCampaignView(true);
            }}
          >
            <i className="ki-outline ki-eye fs-2 text-info" />
          </span>
        </div>
      ),
    },
    {
      title: "Status",
      render: (text) => (
        <div>
          <Switch
            size="small"
            checked={text?.status == 1 ? true : false}
            onChange={(e) => {
              console.log(e, "-e");
              const dayStatus = e ? 1 : 0;
              UpdateDaypartingStatus(dayStatus, text?.id);
              setList((prev) => {
                return prev.map((item) => {
                  if (item?.id === text?.id) {
                    return {
                      ...item,
                      status: text?.status == 1 ? 0 : 1,
                    };
                  }
                  return item;
                });
              });
            }}
          />
        </div>
      ),
    },
    {
      title: "Action",
      key: "action",
      render: (text, record) => (
        <div className="d-flex">
          <span
            className="btn btn-icon bg-light btn-active-color-success btn-sm me-2"
            onClick={() => {
              setUpdateData(record);
              setFlag("update");
              setIsOpenDaypartingConfig(true);
            }}
          >
            <i className="ki-outline ki-pencil fs-2 text-success" />
          </span>
          <span
            className="btn btn-icon bg-light btn-active-color-primary btn-sm"
            onClick={() => {
              setUpdateData(record);
              setFlag("view");
              setIsOpenDaypartingConfig(true);
            }}
          >
            <i className="ki-outline ki-eye fs-2 text-info" />
          </span>
        </div>
      ),
    },
  ];

  const onPageNo = (e) => {
    set_all_filters({ ...all_filters, page: e });
    setLoading(true);
    const apiObj = {
      ...all_filters,
      page: e,
      "per-page": all_filters?.["per-page"],
    };
    GetDaypartingList(apiObj);
  };

  const onPerPage = (e) => {
    set_all_filters({ ...all_filters, page: 1, "per-page": e });
    setLoading(true);
    const apiObj = {
      ...all_filters,
      page: 1,
      "per-page": e,
    };
    GetDaypartingList(apiObj);
  };
  return (
    <>
      <div className="container-fluid" id="kt_content_container">
        <div className="row gy-5 g-xl-5">
          <div className="col-xxl-12">
            <div className="card card-xxl-stretch mb-5 mb-xl-8">
              <div className="card-header border-0">
                <div className="d-flex flex-stack flex-wrap gap-4">
                  <div className="position-relative">
                    <h3 class="card-title align-items-start flex-column">
                      <span class="card-label fw-bold fs-3 mb-1">
                        {isOpenDaypartingConfig && (
                          <span
                            className="btn btn-sm btn-icon btn-light-primary w-40px h-40px me-4"
                            onClick={() => {
                              setIsOpenDaypartingConfig(false);
                            }}
                          >
                            <i className="ki-outline ki-arrow-left fs-1" />
                          </span>
                        )}{" "}
                        Day Parting
                      </span>
                    </h3>
                  </div>
                </div>
                <div className="card-toolbar">
                  <div className="position-relative me-2">
                    <Select
                      className="w-200px"
                      size="large"
                      placeholder="Select Marketplace"
                      options={GetMarketplaceList(marketplace_list)}
                      value={selected_marketplace}
                      onChange={(e) => {
                        set_selected_marketplace(e);
                        set_selected_currency_sign(currency_list[e]);
                        const apiObj = {
                          ...all_filters,
                          offset: 1,
                          "per-page": DefaultPerPage,
                          marketplace_id: e,
                        };
                        set_all_filters(apiObj);
                        setLoading(true);
                        GetDaypartingList(apiObj);
                      }}
                    />
                  </div>
                  {!isOpenDaypartingConfig && (
                    <button
                      className="btn  fs-7 btn-primary fw-bold"
                      onClick={() => {
                        setIsOpenDaypartingConfig(true);
                      }}
                      disabled={allCampaign?.length == 0}
                    >
                      Add Day Parting
                    </button>
                  )}
                </div>
              </div>

              <div className="card-body py-2 main-box">
                <div className="separator separator-dashed mb-3" />
                {isOpenDaypartingConfig ? (
                  <DaypartingConfiguration
                    setIsOpenDaypartingConfig={setIsOpenDaypartingConfig}
                    flag={flag}
                    setFlag={setFlag}
                    updateData={updateData}
                    setUpdateData={setUpdateData}
                    onSuccess={() => {
                      setLoading(true);
                      GetDaypartingList(all_filters);
                      setIsOpenDaypartingConfig(false);
                    }}
                    selected_marketplace={selected_marketplace}
                    allCampaign={allCampaign}
                  />
                ) : loading ? (
                  <NoDataTable columns={columns} type="loading" />
                ) : list?.length > 0 ? (
                  <div className="table-responsive">
                    <EsfTable
                      columns={columns?.map((d) => ({
                        ...d,
                        width: d?.title?.length * 13,
                      }))}
                      sticky={{
                        offsetHeader: "10px",
                      }}
                      scroll={{
                        x: "max-content",
                        y: "calc(100vh - 300px)",
                      }}
                      fixed="top"
                      dataSource={list}
                      rowKey="key"
                      loading={loading}
                      pagination={false}
                    />{" "}
                  </div>
                ) : (
                  <>
                    <NoDataTable columns={columns} type="no-data" />
                  </>
                )}
                <Pagination
                  loading={loading || list?.length === 0}
                  pageSize={all_filters?.["per-page"]}
                  page={all_filters?.page}
                  totalPage={totalPage}
                  onPerPage={onPerPage}
                  onPageNo={onPageNo}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      {campaignView && (
        <CampaignModal
          campaignList={selectedCampaign}
          show={campaignView}
          onHide={() => setCampaignView(false)}
        />
      )}
    </>
  );
};

export default Dayparting;
