import React, { useState, useEffect } from "react";
import SDSteps from "./lib/steps";

import STVCreateCampaign from "./lib/create_campaign";
import STVAdgroup from "./lib/ad_group";
import STVProducts from "./lib/add_products";
import STVManualTargeting from "./lib/manual_targeting";
import STVAddSettings from "./lib/add-settings";

import { DefaultPerPage } from "../../../../../config";
import { MakeApiCall } from "../../../../../apis/make-api-call";
import { DisplayNotification, ConvertParams } from "../../../../../config";

import dayjs from "dayjs";
import {
  dummyProductList,
  dummyDropDownTargetList,
} from "../../../../../config/select-dropdown-list";

const SponsoredTV = () => {
  const [selectedStep, setSelectedStep] = useState("step1");
  const [product_list, set_product_list] = useState([]);
  const [selected_product_list, set_selected_product_list] = useState([]);
  const [targeting_list, set_targeting_list] = useState([]);
  const [selected_targeting_list, set_selected_targeting_list] = useState([]);
  const [totalPage, setTotalPage] = useState(1);

  const [all_data, set_all_data] = useState({
    campaign_ad_type: "SD",
    state: "PAUSED",
    name: "",
    start_date: dayjs()?.format("YYYY-MM-DD"),
    end_date: dayjs().add(30, "d")?.format("YYYY-MM-DD"),
    daily_budget: "",
    daily_limit: "",
    run_indefinitely: false,

    ad_group: [],

    format_image_video: "image",
    landing_page_option: "Advertis Products",
    choose_store: "YOUR_STORE",
    choose_page: "HOME",

    advertise_name: "",
  });

  const [all_common_hide_show, set_all_common_hide_show] = useState({
    add_items: "search",
    target_type: "browse",
  });
  const [all_filters, set_all_filters] = useState({
    offset: 1,
    "per-page": DefaultPerPage,
    search_key: "",
  });

  useEffect(() => {
    GetProductList(all_filters);
    GetTargetingList(all_filters);
  }, []);

  const GetProductList = async (filters) => {
    if (dummyProductList?.status) {
      set_product_list(dummyProductList?.data?.records);
      setTotalPage(dummyProductList?.data?.pagination?.totalCount);
    } else {
      DisplayNotification("error", "Error", dummyProductList?.message);
    }
  };

  const GetTargetingList = async (filters) => {
    if (dummyDropDownTargetList?.status) {
      set_targeting_list(dummyDropDownTargetList?.data?.records);
      setTotalPage(dummyDropDownTargetList?.data?.pagination?.totalCount);
    } else {
      DisplayNotification("error", "Error", dummyDropDownTargetList?.message);
    }
  };

  return <div>Sponsored TV</div>;

  return (
    <>
      <div
        className="stepper stepper-pills stepper-column d-flex flex-column flex-lg-row align-items-start"
        id="kt_stepper_example_vertical"
      >
        <SDSteps
          selectedStep={selectedStep}
          setSelectedStep={setSelectedStep}
        />
        <div className="flex-row-fluid">
          <div style={{ minHeight: 500 }}>
            <div className="mb-5 ">
              {selectedStep === "step1" && (
                <STVCreateCampaign
                  all_data={all_data}
                  set_all_data={set_all_data}
                />
              )}
              {selectedStep === "step2" && (
                <STVAdgroup all_data={all_data} set_all_data={set_all_data} />
              )}
              {selectedStep === "step3" && (
                <STVProducts
                  // selected_adgroup_list={selected_adgroup_list}
                  product_list={product_list}
                  selected_product_list={selected_product_list}
                  set_selected_product_list={set_selected_product_list}
                  all_common_hide_show={all_common_hide_show}
                  set_all_common_hide_show={set_all_common_hide_show}
                />
              )}
              {selectedStep === "step4" && (
                <STVManualTargeting
                  targeting_list={targeting_list}
                  set_selected_targeting_list={set_selected_targeting_list}
                  selected_targeting_list={selected_targeting_list}
                  all_common_hide_show={all_common_hide_show}
                  set_all_common_hide_show={set_all_common_hide_show}
                />
              )}
              {selectedStep === "step5" && (
                <STVAddSettings
                  all_data={all_data}
                  set_all_data={set_all_data}
                />
              )}
            </div>
          </div>

          <div className="d-flex flex-stack">
            <div className="me-2">
              {selectedStep !== "step1" && (
                <button
                  type="button"
                  className="btn btn-dark fw-bold fs-7"
                  onClick={() => {
                    const newStep =
                      selectedStep === "step5"
                        ? "step4"
                        : selectedStep === "step4"
                        ? "step3"
                        : selectedStep === "step3"
                        ? "step2"
                        : "step1";
                    setSelectedStep(newStep);
                  }}
                >
                  Back
                </button>
              )}
            </div>

            <div>
              {selectedStep == "step5" && (
                <button type="button" className="btn btn-primary fs-7 fw-bold">
                  <span className="indicator-label">Launch Campaign</span>
                  <span className="indicator-progress">
                    Please wait...
                    <span className="spinner-border spinner-border-sm align-middle ms-2" />
                  </span>
                </button>
              )}

              {selectedStep !== "step5" && (
                <button
                  type="button"
                  className="btn btn-primary fs-7 fw-bold"
                  onClick={() => {
                    console.log("all data >>>>>", all_data);
                    const newStep =
                      selectedStep === "step1"
                        ? "step2"
                        : selectedStep === "step2"
                        ? "step3"
                        : selectedStep === "step3"
                        ? "step4"
                        : selectedStep === "step4"
                        ? "step5"
                        : "step6";
                    setSelectedStep(newStep);
                  }}
                >
                  Continue
                </button>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default SponsoredTV;
