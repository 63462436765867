import {
  Checkbox,
  Dropdown,
  Input,
  message,
  Popconfirm,
  Select,
  Switch,
  Tag,
  theme,
} from "antd";
import { CloudUploadOutlined } from "@ant-design/icons";
import React, { useContext, useEffect, useState } from "react";
import {
  ConvertParams,
  DefaultPerPage,
  DisplayNotification,
  downloadFile,
  GetMarketplaceDropdown,
  GetMarketplaceList,
  isNot2000SeriesYear,
} from "../../../../config";
import NoDataTable from "../../../../component/no-data-table";
import EsfTable from "../../../../component/esf-table";
import Pagination from "../../../../component/esf-table/lib/pagination";
import { MakeApiCall } from "../../../../apis/make-api-call";
import CampaignEdit from "./lib/campaign-edit";
import UpdateBulkCampaign from "./lib/update_bulk_campaign";
import { CampaignWrapper } from "../style";
import { GlobalContext } from "../../../../commonContext";
const { useToken } = theme;
const CampaignList = (props) => {
  const {
    selected_marketplace,
    set_selected_marketplace,
    marketplace_list,
    set_marketplace_list,
    currency_list,
    set_currency_list,
    selected_currency_sign,
    set_selected_currency_sign,
  } = props;
  const { token } = useToken();
  const contentStyle = {
    backgroundColor: token.colorBgElevated,
    borderRadius: token.borderRadiusLG,
    boxShadow: token.boxShadowSecondary,
    minWidth: "150px",
  };

  const contextValue = useContext(GlobalContext);
  const [loading, setLoading] = useState(true);
  const [totalPage, setTotalPage] = useState(1);

  const [list, setList] = useState([]);
  const [selectedCampaign, setSelectedCampaign] = useState([]);
  const [isAllCampaign, setIsAllCampaign] = useState(false);
  const [isOpenEditCampaign, setIsOpenEditCampaign] = useState(false);
  const [edit_campaign_data, set_edit_campaign_data] = useState({});
  const [isOpenBulkOperationModal, setIsOpenBulkOperationModal] = useState();
  const [bulkOperationType, setbulkOperationType] = useState();
  const [updatingCampaignStatus, setUpdatingCampaignStatus] = useState(false);
  const [portfolioList, setPortfolioList] = useState([]);
  const [columnConfigLoading, setColumnConfigLoading] = useState(false);
  const [selectedFilterCheckbox, setSelectedFilterCheckbox] = useState([
    "ad_type",
    "targeting_settings",
    "state",
    "budget_type",
    "start_date",
    "end_date",
    "budget_amount",
    "impressions",
    "clicks",
    "CTR",
    "spend",
    "CPC",
    "sales",
    "ACoS",
    "ROAS",
    "new_to_brand_orders",
    "unit_ordered",
  ]);

  const [all_filters, set_all_filters] = useState({
    offset: 1,
    "per-page": DefaultPerPage,
    marketplace_id: null,
    search_key: "",
    ad_type: null,
    campaign_name: "",
    state: null,
  });

  useEffect(() => {
    GetMarketplace();
  }, [contextValue?.data?.selected_sub_user]);

  const GetMarketplace = async () => {
    const marketplace = await GetMarketplaceDropdown();
    if (marketplace?.marketplace_list?.length > 0) {
      set_marketplace_list(marketplace?.marketplace_list);
      set_selected_marketplace(
        marketplace?.marketplace_list[0]?.marketplace_id
      );
      set_currency_list(marketplace?.currency_list);
      set_selected_currency_sign(
        marketplace?.currency_list[
          marketplace?.marketplace_list[0]?.marketplace_id
        ]
      );
      set_all_filters({
        ...all_filters,
        marketplace_id: marketplace?.marketplace_list[0]?.marketplace_id,
      });
      GetCampaignList({
        ...all_filters,
        marketplace_id: marketplace?.marketplace_list[0]?.marketplace_id,
      });
      GetPortfolioList();
    }
  };

  const GetCampaignList = async (filters) => {
    const response = await MakeApiCall(
      `advertising/get-all-campaigns-list${ConvertParams(filters)}`,
      `get`,
      null,
      true
    );
    if (response?.status) {
      setLoading(false);
      setSelectedFilterCheckbox(response?.data?.campaign_filter);
      setList(response?.data?.table_data);
      setTotalPage(response?.data?.pagination?.totalCount);
    } else {
      setLoading(false);
      DisplayNotification("error", "Error", response?.message);
    }
  };
  const GetPortfolioList = async () => {
    const response = await MakeApiCall(
      `advertising/all-portfolios?marketplace_id=ATVPDKIKX0DER`,
      `get`,
      null,
      true
    );
    if (response?.status) {
      setPortfolioList(response?.data);
    } else {
      setPortfolioList([]);
      DisplayNotification("error", "Error", response?.message);
    }
  };

  const SaveCampaignStatus = async (state, campaignList) => {
    const response = await MakeApiCall(
      `advertising/update-bulk-campaign`,
      `put`,
      {
        ids: campaignList,
        campaign_status: state,
      },
      true
    );
    if (response?.status) {
      GetCampaignList(all_filters);
    } else {
      GetCampaignList(all_filters);
      DisplayNotification("error", "Error", response?.message);
    }
  };

  const items = [
    {
      key: "1",
      label: (
        <span
          onClick={() => {
            setIsOpenBulkOperationModal(true);
            setbulkOperationType("status");
          }}
        >
          Update Status
        </span>
      ),
    },
  ];
  const defaultColumns = [
    {
      title: () => {
        return (
          <Checkbox
            checked={isAllCampaign}
            size="large"
            onChange={(e) => {
              if (e.target.checked) {
                const allCamp = list?.map((d) => d?.campaign_id);
                setSelectedCampaign(allCamp);
              } else {
                setSelectedCampaign([]);
              }
              setIsAllCampaign(e.target.checked);
            }}
          />
        );
      },
      ellipsis: true,
      align: "left",
      fixed: "left",
      width: 80,
      render: (text) => {
        return (
          <>
            {" "}
            <Checkbox
              checked={
                selectedCampaign.filter((a) => a == text?.campaign_id)
                  ?.length == 1
                  ? true
                  : false
              }
              size="large"
              onChange={(e) => {
                const checkboxId = text?.campaign_id;
                setSelectedCampaign((prev) => {
                  if (e?.target?.checked) {
                    return [...prev, checkboxId];
                  } else {
                    return prev.filter(
                      (campaign_id) => campaign_id !== checkboxId
                    );
                  }
                });
              }}
            />
            <Switch
              // disabled={updatingCampaignStatus}
              className="ms-2"
              checked={text?.state === "ENABLED" ? true : false}
              onChange={(e) => {
                setUpdatingCampaignStatus(true);
                SaveCampaignStatus(e ? "ENABLED" : "PAUSED", [
                  text?.campaign_id,
                ]);
                setList((prev) => {
                  return prev.map((item) => {
                    if (item?.campaign_id === text?.campaign_id) {
                      return {
                        ...item,
                        state: text?.state === "ENABLED" ? "PAUSED" : "ENABLED",
                      };
                    }
                    return item;
                  });
                });
              }}
            />
          </>
        );
      },
    },
    {
      title: "Sr. No.",
      ellipsis: true,
      align: "left",
      fixed: "left",
      width: 80,
      render: (text) => {
        return (
          <span>
            {(all_filters?.offset - 1) * all_filters?.["per-page"] +
              1 +
              text.key}
          </span>
        );
      },
    },
    {
      title: "Name",
      // dataIndex: "name",
      // key: "name",
      width: 300,
      dataIndex: "campaign_name",
      key: "campaign_name",
      render: (text) => {
        return <span className="fw-bolder">{text}</span>;
      },
    },
  ];
  const actionColumns = [
    {
      title: "Action",
      fixed: "right",
      width: 100,
      render: (text) => {
        return (
          <div className="d-flex justify-content-between">
            <div className="mx-1">
              <button
                class="btn btn-icon btn-bg-light btn-active-color-success btn-sm"
                onClick={() => {
                  set_edit_campaign_data(text);
                  setIsOpenEditCampaign(true);
                }}
              >
                <i class="ki-outline ki-pencil fs-2 text-success"></i>
              </button>
            </div>

            {/* <Popconfirm
              title="Are you sure to delete this campaign ?"
              onConfirm={() => {
                // console.log(
                //   [{ campaign_id: text?.campaign_id }],
                //   "[ { campaign_id: text?.campaign_id },]"
                // );
                // message.destroy();
                // message.loading("Deleting...", 0);
                // DeleteWalmartCampaignAction([
                //   { campaign_id: text?.campaign_id },
                // ]);
              }}
              onCancel={() => {}}
              okText="Yes"
              cancelText="No"
            >
              <div className="mx-1">
                <button class="btn btn-icon btn-bg-light btn-active-color-primary btn-sm">
                  <i class="ki-outline ki-trash fs-2 text-danger"></i>
                </button>
              </div>
            </Popconfirm> */}
          </div>
        );
      },
    },
  ];
  const columns = [
    {
      title: "Campaign Type",
      // dataIndex: "campaignType",
      // key: "campaignType",
      dataIndex: "ad_type",
      key: "ad_type",
      width: 110,
      render: (text) => {
        return (
          <Tag
            className="fw-bold"
            color={
              text === "SPONSORED_PRODUCTS"
                ? "gold"
                : text === "SPONSORED_DISPLAY"
                ? "green"
                : text === "SPONSORED_BRAND"
                ? "geekblue"
                : "purple"
            }
            style={{ textTransform: "capitalize" }}
            // bordered={false}
          >
            {text}
          </Tag>
        );
      },
    },
    {
      title: "Targeting Type",
      // dataIndex: "targetingType",
      // key: "targetingType",
      dataIndex: "targeting_settings",
      key: "targeting_settings",
      width: 140,
      render: (text) => {
        return (
          <Tag
            className="fw-bolder"
            color={
              text === "MANUAL" ? "magenta" : text === "AUTO" ? "blue" : ""
            }
            style={{ textTransform: "capitalize" }}
            bordered={false}
          >
            {text}
          </Tag>
        );
      },
    },
    {
      title: "Status",
      // dataIndex: "status",
      // key: "status",
      dataIndex: "state",
      key: "state",
      width: 110,
      render: (text, _) => {
        return (
          <>
            {" "}
            <Tag
              className="fw-bolder"
              color={
                text === "Proposal"
                  ? "blue"
                  : text === "ENABLED"
                  ? "green"
                  : text === "PAUSED"
                  ? "orange"
                  : text === "COMPLETED"
                  ? "purple"
                  : "error"
              }
            >
              {text}
            </Tag>
          </>
        );
      },
    },
    {
      title: "Budget Type",
      // dataIndex: "budgetType",
      // key: "budgetType",
      dataIndex: "budget_type",
      key: "budget_type",
      width: 130,
    },
    {
      title: "Start Date",
      dataIndex: "start_date",
      key: "start_date",
      // dataIndex: "startDate",
      // key: "startDate",
      width: 120,
    },
    {
      title: "End Date  ",
      // dataIndex: "endDate",
      // key: "endDate",
      dataIndex: "end_date",
      key: "end_date",
      width: 110,
      render: (text) => {
        const year = isNot2000SeriesYear(text);
        return <label>{year ? "No End Date" : text}</label>;
      },
    },
    {
      title: "Total Budget",
      // dataIndex: "totalBudget",
      // key: "totalBudget",
      dataIndex: "budget_amount",
      key: "budget_amount",
      width: 120,
      render: (text) => {
        return (
          <span className="">
            {selected_currency_sign}
            {text}
          </span>
        );
      },
    },

    // {
    //   title: "Cost Type",
    //   dataIndex: "type",
    //   key: "type",
    //   render: (text) => {
    //     return <span>{text || "-"}</span>;
    //   },
    // },
    {
      title: "Impressions",
      dataIndex: "impressions",
      key: "impressions",
      width: 115,
      render: (text) => {
        return <span>{text || "0"}</span>;
      },
    },
    {
      title: "Clicks",
      dataIndex: "clicks",
      key: "clicks",
      width: 80,
      render: (text) => {
        return <span>{text || "0"}</span>;
      },
    },
    {
      title: "CTR",
      dataIndex: "CTR",
      key: "CTR",
      width: 80,
      render: (text) => {
        return <span>{text || "0"}%</span>;
      },
    },
    {
      title: "Spend",
      dataIndex: "spend",
      key: "spend",
      width: 80,
      render: (text) => {
        return (
          <span>
            {selected_currency_sign}
            {text || "0"}
          </span>
        );
      },
    },
    {
      title: "CPC",
      dataIndex: "CPC",
      key: "CPC",
      width: 80,
      render: (text) => {
        return (
          <span>
            {selected_currency_sign}
            {text || "0"}
          </span>
        );
      },
    },
    // {
    //   title: "Orders",
    //   dataIndex: "unit_ordered",
    //   key: "unit_ordered",
    //   width: 100,
    //   render: (text) => {
    //     return <span>{ text || "0"}</span>;
    //   },
    // },
    {
      title: "Sales",
      dataIndex: "sales",
      key: "sales",
      width: 80,
      render: (text) => {
        return (
          <span>
            {selected_currency_sign}
            {text || "0"}
          </span>
        );
      },
    },
    {
      title: "ACoS",
      dataIndex: "ACoS",
      key: "ACoS",
      width: 80,
      render: (text) => {
        return <span>{text || "0"}%</span>;
      },
    },
    {
      title: "RoAS",
      dataIndex: "ROAS",
      key: "ROAS",
      width: 80,
      render: (text) => {
        return (
          <span>
            {selected_currency_sign}
            {text || "0"}
          </span>
        );
      },
    },
    {
      title: "NTB Orders",
      dataIndex: "new_to_brand_orders",
      key: "new_to_brand_orders",
      width: 120,
      render: (text) => {
        return (
          <span>
            {selected_currency_sign}
            {text || "0"}
          </span>
        );
      },
    },
    {
      title: "Units Sold",
      dataIndex: "unit_ordered",
      key: "unit_ordered",
      width: 110,
      render: (text) => {
        return <span>{text || "0"}</span>;
      },
    },
    // {
    //   title: "Daily Budget",
    //   // dataIndex: "dailyBudget",
    //   // key: "dailyBudget",
    //   dataIndex: "daily_limit",
    //   key: "daily_limit",
    //   render: (text) => {
    //     return <span className="">{selected_currency_sign}{text}</span>;
    //   },
    // },

    // {
    //   title: "Campaign ID",
    //   // dataIndex: "campaignId",
    //   // key: "campaignId",
    //   dataIndex: "campaign_id",
    //   key: "campaign_id",
    // },
    // {
    //   title: "Bidding Strategy",
    //   dataIndex: "bidding_strategy",
    //   key: "bidding_strategy",
    //   render: (biddingStrategy) => {
    //     const data = JSON.parse(biddingStrategy);
    //     return data?.strategy;
    //   },
    // },
    // {
    //   title: "Channel",
    //   dataIndex: "channel",
    //   key: "channel",
    // },
  ];

  const onPageNo = (e) => {
    set_all_filters({ ...all_filters, offset: e });
    setLoading(true);
    const apiObj = {
      ...all_filters,
      offset: e,
      "per-page": all_filters?.["per-page"],
    };
    GetCampaignList(apiObj);
  };

  const onPerPage = (e) => {
    set_all_filters({ ...all_filters, offset: 1, "per-page": e });
    setLoading(true);
    const apiObj = {
      ...all_filters,
      offset: 1,
      "per-page": e,
    };
    GetCampaignList(apiObj);
  };

  const checkoxOptions = columns?.map((d) => ({
    label: d?.title,
    value: d?.dataIndex,
  }));

  function downloadXlsxFile(url) {
    fetch(url)
      .then((response) => {
        return response.blob();
      })
      .then((blob) => {
        console.log(blob, "-----------------");
        if (validateBlobFile(blob)) {
          // Create a temporary URL for the blob
          const fileURL = URL.createObjectURL(blob);
          console.log(fileURL, "blob");

          // Create a link element
          const link = document.createElement("a");
          link.href = fileURL;
          link.download = `campaign_list.xlsx`; // Specify the desired filename

          // Simulate a click on the link to initiate the download
          message.destroy();
          link.click();
          // Clean up the temporary URL
          URL.revokeObjectURL(fileURL);
        } else {
          message.destroy();
          handleBlobError(blob);
        }
      })
      .catch((error) => {
        message.destroy();
        message.error("Error in file");
      });
  }

  function validateBlobFile(blob) {
    if (
      blob.type !==
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
    ) {
      return false;
    }
    return true;
  }

  const handleBlobError = async (blob) => {
    try {
      console.log(blob, "blob");
      const newBlob = await blob.text();
      message.destroy();
      message.error(newBlob.split(".")?.[0]);
    } catch (error) {
      return "Invalid File";
      // Handle the error accordingly
    }
  };

  const SaveCampaignColumnConfig = async () => {
    message.loading("Saving...", 0);
    const response = await MakeApiCall(
      `advertising/update-campaign-filter`,
      `put`,
      {
        campaign_filter: selectedFilterCheckbox,
      },
      true
    );
    if (response?.status) {
      setLoading(true);
      setColumnConfigLoading(false);
      GetCampaignList(all_filters);
      message.destroy();
      DisplayNotification("success", "Success", response?.message);
    } else {
      message.destroy();
      setColumnConfigLoading(false);
      DisplayNotification("error", "Error", response?.message);
    }
  };

  return (
    <CampaignWrapper>
      <div className="row">
        {/*begin::Col*/}
        <div className="col-xxl-12">
          {/*begin::Tables Widget 9*/}
          <div className="card card-xxl-stretch mb-5 mb-xl-8">
            {/*begin::Header*/}
            <div className="card-header border-0">
              <div className="d-flex flex-stack flex-wrap gap-4 p-2">
                <div className="position-relative me-2">
                  <Input
                    style={{ width: "250px" }}
                    prefix={
                      <i class="ki-outline ki-magnifier text-gray-800 fs-1"></i>
                    }
                    placeholder="Search by Campaign name"
                    size="large"
                    value={all_filters?.campaign_name}
                    onChange={(e) => {
                      const apiObj = {
                        ...all_filters,
                        campaign_name: e.target.value,
                      };
                      set_all_filters(apiObj);
                    }}
                    onPressEnter={(e) => {
                      const apiObj = {
                        ...all_filters,
                        campaign_name: e.target.value,
                      };
                      setLoading(true);
                      GetCampaignList(apiObj);
                    }}
                  />
                </div>
              </div>
              <div className="card-toolbar">
                <div className="position-relative me-2">
                  <button
                    className="btn export-button h-40px"
                    onClick={() => {
                      const userId = JSON.parse(
                        contextValue?.data?.user_data
                      )?.id;
                      console.log(userId, "=userId");
                      const url = `https://amazonconsultancy.co.uk/esf-ppc-tool-php/advertising/campaigns-analytics/campaign-report-export?id=${btoa(
                        userId
                      )}${
                        all_filters?.ad_type
                          ? `&ad_type=${all_filters?.ad_type}`
                          : ""
                      }${
                        all_filters?.campaign_name
                          ? `&campaign_name=${all_filters?.campaign_name}`
                          : ""
                      }${
                        all_filters?.state ? `&state=${all_filters?.state}` : ""
                      }&marketplace_id=${selected_marketplace}`;
                      downloadXlsxFile(url);
                    }}
                  >
                    <CloudUploadOutlined />
                    Export
                  </button>
                </div>
                <div className="position-relative me-2">
                  <Select
                    options={[
                      {
                        label: "Sponsored Product",
                        value: "SPONSORED_PRODUCTS",
                      },
                      {
                        label: "Sponsored Display",
                        value: "SPONSORED_DISPLAY",
                      },
                      { label: "Sponsored Brand", value: "SPONSORED_BRAND" },
                      { label: "Sponsored TV", value: "SPONSORED_TV" },
                    ]}
                    className="w-200px"
                    size="large"
                    allowClear
                    value={all_filters?.ad_type}
                    onChange={(e) => {
                      const apiObj = {
                        ...all_filters,
                        offset: 1,
                        "per-page": DefaultPerPage,
                        ad_type: e,
                      };
                      setLoading(true);
                      set_all_filters(apiObj);
                      GetCampaignList(apiObj);
                    }}
                    placeholder="Campaign Type"
                  />
                </div>
                <div className="position-relative me-2">
                  {console.log(all_filters, "all_filters")}
                  <Select
                    options={[
                      { label: "PAUSED", value: "PAUSED" },
                      { label: "ENABLED", value: "ENABLED" },
                    ]}
                    className="w-200px"
                    size="large"
                    allowClear
                    value={all_filters?.state}
                    onChange={(e) => {
                      const apiObj = {
                        ...all_filters,
                        offset: 1,
                        "per-page": DefaultPerPage,
                        state: e,
                      };
                      console.log(apiObj, "apiObj");

                      setLoading(true);
                      set_all_filters(apiObj);
                      GetCampaignList(apiObj);
                    }}
                    placeholder="Campaign Status"
                  />
                </div>
                <div className="position-relative me-2">
                  <Select
                    className="w-200px"
                    size="large"
                    placeholder="Select Marketplace"
                    options={GetMarketplaceList(marketplace_list)}
                    value={selected_marketplace}
                    onChange={(e) => {
                      set_selected_marketplace(e);
                      set_selected_currency_sign(currency_list[e]);
                      const apiObj = {
                        ...all_filters,
                        offset: 1,
                        "per-page": DefaultPerPage,
                        marketplace_id: e,
                      };
                      setLoading(true);
                      set_all_filters(apiObj);
                      GetCampaignList(apiObj);
                    }}
                  />
                </div>
                <div className="position-relative me-2">
                  <Dropdown
                    disabled={list === 0 || loading || columnConfigLoading}
                    trigger={["click"]}
                    dropdownRender={() => (
                      <div style={contentStyle}>
                        <div>
                          <div
                            style={{
                              minHeight: "206px",
                              minWidth: "170px",
                              border: "1px solid #e6332a8a",
                              borderRadius: "7px",
                              overflow: "auto",
                            }}
                          >
                            <div className="my-3" />
                            <Checkbox.Group
                              value={selectedFilterCheckbox}
                              options={checkoxOptions}
                              onChange={(value) => {
                                setSelectedFilterCheckbox(value);
                              }}
                              style={{
                                display: "grid",
                                justifyContent: "center",
                              }}
                            />
                            <div className="separator border-gray-200 mt-3 mb-2" />
                            <div className="mb-2 text-end">
                              {" "}
                              <button
                                className="btn btn-primary btn-sm me-4"
                                disabled={columnConfigLoading}
                                onClick={() => {
                                  setColumnConfigLoading(true);
                                  SaveCampaignColumnConfig();
                                }}
                              >
                                Save
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    )}
                  >
                    <button className="btn btn-primary btn-sm">
                      Select Filter
                    </button>
                  </Dropdown>
                </div>
                <div className="position-relative me-2">
                  <Dropdown
                    menu={{
                      items,
                    }}
                    placement="bottomRight"
                    disabled={selectedCampaign?.length > 0 ? false : true}
                  >
                    <button className="btn btn-light-primary btn-sm ">
                      Update Campaign
                    </button>
                  </Dropdown>
                </div>
              </div>
            </div>
            {/*end::Header*/}
            {/*begin::Body*/}
            <div className="card-body py-2">
              {/*begin::Table container*/}
              <div className="table-responsive">
                {loading ? (
                  <NoDataTable columns={columns} type="loading" />
                ) : list?.length > 0 ? (
                  <EsfTable
                    columns={[
                      ...defaultColumns,
                      ...columns?.filter((d) =>
                        selectedFilterCheckbox?.includes(d?.dataIndex)
                      ),
                      ...actionColumns,
                    ]}
                    sticky={{
                      offsetHeader: "10px",
                    }}
                    scroll={{
                      x: "max-content",
                      y: "calc(100vh - 330px)",
                    }}
                    fixed="top"
                    dataSource={list}
                    rowKey="key"
                    loading={loading}
                    pagination={false}
                  />
                ) : (
                  <>
                    <NoDataTable columns={columns} type="no-data" />
                  </>
                )}
              </div>
              <Pagination
                loading={loading || list?.length === 0}
                pageSize={all_filters?.["per-page"]}
                page={all_filters?.offset}
                totalPage={totalPage}
                onPerPage={onPerPage}
                onPageNo={onPageNo}
              />
            </div>
            {/*begin::Body*/}
          </div>
          {/*end::Tables Widget 9*/}
        </div>
        {/*end::Col*/}
      </div>
      {isOpenEditCampaign && (
        <CampaignEdit
          show={isOpenEditCampaign}
          data={edit_campaign_data}
          portfolioList={portfolioList}
          selected_marketplace={selected_marketplace}
          onHide={() => {
            setIsOpenEditCampaign(false);
            GetCampaignList(all_filters);
            set_edit_campaign_data({});
          }}
        />
      )}

      {isOpenBulkOperationModal && (
        <UpdateBulkCampaign
          show={isOpenBulkOperationModal}
          operationType={bulkOperationType}
          selectedCampaign={selectedCampaign}
          setLoading={setLoading}
          onHide={() => {
            setIsOpenBulkOperationModal(false);
          }}
          onSuccess={() => {
            setLoading(true);
            GetCampaignList(all_filters);
            setbulkOperationType("");
            setSelectedCampaign([]);
            setIsOpenBulkOperationModal(false);
          }}
        />
      )}
    </CampaignWrapper>
  );
};

export default CampaignList;
