import React, { useEffect, useState } from "react";
import Chart from "react-apexcharts";
import { NumberWithCommas } from "../../../../config";
import { message, Skeleton } from "antd";
import NoData from "../../../../component/no-data";
import { Link } from "react-router-dom";

export default function (props) {
  const {
    currency_sign,
    targetingCardGraphData,
    targetingCardData,
    targetingCardGraphLoading,
    targetingCardLoading,
    chartRef,
    setIsOpenActiveKeywordModal,
    GetActiveKeywords,
    start_date,
    end_date,
  } = props;
  const [totalAdSpend, setTotalAdSpend] = useState([]);
  const [totalAdSales, setTotalAdSales] = useState([]);
  const [totalACOS, setTotalACOS] = useState([]);
  const [totalROAS, setTotalROAS] = useState([]);
  const [noDataSeries, setNoDataSeries] = useState([1]);

  const [totalAdSpendLabel, setTotalAdSpendLabel] = useState([]);
  const [totalAdSalesLabel, setTotalAdSalesLabel] = useState([]);
  const [totalACOSLabel, setTotalACOSLabel] = useState([]);
  const [totalROASLabel, setTotalROASLabel] = useState([]);

  useEffect(() => {
    setTotalAdSpendLabel([]);
    setTotalAdSalesLabel([]);
    setTotalACOSLabel([]);
    setTotalROASLabel([]);

    setTotalAdSpend([]);
    setTotalAdSales([]);
    setTotalACOS([]);
    setTotalROAS([]);
  }, [targetingCardGraphLoading]);

  useEffect(() => {
    if (targetingCardGraphData?.total_ad_spends) {
      const newAdSPendData = Object?.entries(
        targetingCardGraphData?.total_ad_spends
      )?.map((d, i) => {
        totalAdSpendLabel?.push(d?.[0]);
        return parseFloat(d[1]);
      });
      setTotalAdSpendLabel(totalAdSpendLabel || []);
      setTotalAdSpend(newAdSPendData || []);
    }

    if (targetingCardGraphData?.total_ad_sales) {
      const newAdSalesData = Object?.entries(
        targetingCardGraphData?.total_ad_sales
      )?.map((d, i) => {
        totalAdSalesLabel?.push(d?.[0]);
        return parseFloat(d[1]);
      });
      setTotalAdSalesLabel(totalAdSalesLabel);
      setTotalAdSales(newAdSalesData);
    }

    if (targetingCardGraphData?.total_acos) {
      const newACOSData = Object?.entries(
        targetingCardGraphData?.total_acos
      )?.map((d, i) => {
        totalACOSLabel?.push(d?.[0]);
        return parseFloat(d[1]);
      });
      setTotalACOSLabel(totalACOSLabel);
      setTotalACOS(newACOSData);
    }

    if (targetingCardGraphData?.total_roas) {
      const newROASData = Object?.entries(
        targetingCardGraphData?.total_roas
      )?.map((d, i) => {
        totalROASLabel?.push(d?.[0]);
        return parseFloat(d[1]);
      });
      setTotalROASLabel(totalROASLabel);
      setTotalROAS(newROASData);
    }
  }, [targetingCardGraphData]);

  const getMyChart = (label, data) => {
    const options = {
      chart: {
        height: 200,
        type: "bar",
        toolbar: {
          show: false,
        },
      },
      plotOptions: {
        bar: {
          borderRadius: 5,
          dataLabels: {
            position: "top",
          },
          distributed: true,
          columnWidth: "50%",
        },
      },
      noData: {
        text: "No Data",
        align: "center",
        verticalAlign: "middle",
        offsetX: 0,
        offsetY: -5,
        style: {
          color: undefined,
          fontSize: "20px",
          fontFamily: undefined,
        },
      },
      dataLabels: {
        enabled: false,
      },
      xaxis: {
        categories: label,
        position: "top",
        axisBorder: {
          show: false,
        },
        axisTicks: {
          show: false,
        },
        tooltip: {
          enabled: true,
        },
      },
      yaxis: {
        axisBorder: {
          show: false,
        },
        axisTicks: {
          show: false,
        },
        labels: {
          show: true,
          formatter: function (val) {
            return val;
          },
        },
      },
      colors: ["#b3001b", "#ff6f61", "#ff9f80", "#ffcccb", "#e68585"],
      legend: {
        show: true,
        fontSize: "12px",
        labels: {
          colors: "#000",
          useSeriesColors: false,
        },
        itemMargin: {
          horizontal: 10,
          vertical: 5,
        },
        markers: {
          width: 12,
          height: 12,
          radius: 2,
        },
      },
    };

    const seriesData = [
      {
        name: "",
        data: data,
      },
    ];

    return (
      <Chart options={options} series={seriesData} type="bar" height="200" />
    );
  };

  const NoDataChart = () => {
    const options = {
      chart: {
        type: "donut",
      },
      plotOptions: {
        pie: {
          startAngle: -90,
          endAngle: 270,
        },
      },
      noData: {
        text: "No Data",
        align: "center",
        verticalAlign: "middle",
        style: {
          fontSize: "20px",
        },
      },
      dataLabels: {
        enabled: false,
      },
      fill: {
        type: "gradient",
      },
      legend: false,
      title: {
        text: "",
      },
      // colors: ["#282828", "#193882", "#2C4EA3", "#0E6EA9", "#328CA9"],
      colors: ["#cccccc"],
      labels: ["No Data"],
      // responsive: [
      //   {
      //     breakpoint: 480,
      //     options: {
      //       chart: {
      //         width: 100,
      //       },
      //       legend: {
      //         position: "bottom",
      //       },
      //     },
      //   },
      // ],
    };
    return (
      <Chart
        options={options}
        series={noDataSeries}
        type="donut"
        height="170"
      />
    );
  };
  return (
    <>
      <div className="row" ref={chartRef}>
        <div className="col-lg-6">
          <div
            className="card card-xxl-stretch mb-5"
            style={{ border: "1px dashed #f0a6a2" }}
          >
            <>
              {/*begin::Header*/}
              <div className="card-header border-bottom-0 min-h-45px">
                <h3 className="card-title align-items-start flex-column">
                  <span className="card-label fw-bolder text-dark">
                    Total Ad Spend
                  </span>
                  {/* <span class="text-gray-400 pt-1 fw-semibold fs-6">Sales analytics by SKU</span> */}
                </h3>
                <div className="card-toolbar">
                  {targetingCardGraphLoading ? (
                    <Skeleton.Button active />
                  ) : (
                    <span className="text-gray-800 fw-bolder text-hover-primary fs-2">
                      {currency_sign}
                      {NumberWithCommas(
                        targetingCardGraphData?.all_performance_total
                          ?.all_ad_spends_total
                      )}
                    </span>
                  )}
                </div>
                {/* <div className="card-toolbar">
              <div className="px-2 py-3 rounded d-flex w-100 align-items-start justify-content-between">
                <div className="me-10">
                  <span className="badge badge-light-success d-flex align-items-center w-100 ms-2 fs-6 fw-bolder text-green">
                    + 84.57%{" "}
                    <i className="ki-outline ki-arrow-up fs-3 text-green" />
                  </span>
                </div>
                <div>
                  <span className="text-gray-800 fw-bolder text-hover-primary fs-2">
                    $4,687.32
                  </span>{" "}
                  <br />
                  <span className="fw-bold fs-6 d-flex text-start text-dark ps-0">
                    $2,539.56
                  </span>
                </div>
              </div>
            </div> */}
              </div>
              {/*end::Header*/}
              {/*begin::Body*/}
              <div className="card-body" style={{ padding: "3px 10px" }}>
                <div className=" d-flex justify-content-between align-items-center">
                  <div className=" pe-2" style={{ width: "65%" }}>
                    {targetingCardGraphLoading ? (
                      <>
                        <Skeleton.Node
                          active
                          style={{ fontSize: "35px", color: "#a0a0a0" }}
                        />
                      </>
                    ) : (
                      <div className="mt-0 " id="chart-new">
                        {/* {totalAdSpendLabel?.length != 0 &&
                        totalAdSpend?.length !== 0 ? ( */}
                        <div>{getMyChart(totalAdSpendLabel, totalAdSpend)}</div>
                        {/* ) : (
                          NoDataChart()
                        )} */}
                      </div>
                    )}
                  </div>
                  <div
                    className=""
                    style={{
                      width: "35%",
                      borderLeft: "1px dashed #f0a6a2",
                      paddingLeft: "10px",
                    }}
                  >
                    {/* <div className="border border-dashed border-gray-300 p-2 rounded mb-4 d-flex w-100 align-items-center justify-content-between">
                    <div className="me-4">
                      <span className="badge badge-light-success d-flex align-items-center w-100 ms-2 fs-8 fw-bolder text-green">
                        + 84.57%{" "}
                        <i className="ki-outline ki-arrow-up fs-3 text-green" />
                      </span>
                    </div>
                    <span className="fw-bold fs-6 d-flex text-start text-dark ps-0">
                      2,539.56 %
                    </span>
                  </div> */}
                    <div className="d-flex flex-column ">
                      {!targetingCardGraphLoading &&
                      targetingCardGraphData?.total_ad_spends?.length == 0 ? (
                        <NoData
                          height="200px"
                          type="new"
                          customWidth="70px"
                          customFontSize="12px"
                        />
                      ) : (
                        ""
                      )}
                      <div className="d-flex fw-semibold align-items-center">
                        {targetingCardGraphLoading ? (
                          <Skeleton.Input size="small" active block />
                        ) : targetingCardGraphData?.total_ad_spends?.Auto ? (
                          <>
                            {/* <div
                              className="bullet w-10px h-4px rounded-2  me-3"
                              style={{ background: "#b3001b" }}
                            /> */}
                            <div className="text-gray-700 flex-grow-1 me-3">
                              Auto
                            </div>
                            <div className="fw-bolder text-gray-800 fs-6 text-xxl-end">
                              {currency_sign}
                              {NumberWithCommas(
                                targetingCardGraphData?.total_ad_spends?.Auto ||
                                  0
                              )}
                            </div>
                          </>
                        ) : (
                          ""
                        )}
                      </div>
                      {targetingCardGraphData?.total_ad_spends?.Auto ? (
                        <div className="separator separator-dashed my-1" />
                      ) : (
                        ""
                      )}
                      <div className="d-flex fw-semibold align-items-center">
                        {targetingCardGraphLoading ? (
                          <Skeleton.Input size="small" active block />
                        ) : targetingCardGraphData?.total_ad_spends?.PAT ? (
                          <>
                            {/* <div
                              className="bullet w-10px h-4px rounded-2  me-3"
                              style={{ background: "#ff6f61" }}
                            /> */}
                            <div className="text-gray-700 flex-grow-1 me-3">
                              PAT
                            </div>
                            <div className="fw-bolder text-gray-800 fs-6 text-xxl-end">
                              {currency_sign}
                              {NumberWithCommas(
                                targetingCardGraphData?.total_ad_spends?.PAT ||
                                  0
                              )}
                            </div>
                          </>
                        ) : (
                          ""
                        )}
                      </div>
                      {targetingCardGraphData?.total_ad_spends?.PAT ? (
                        <div className="separator separator-dashed my-1" />
                      ) : (
                        ""
                      )}
                      <div className="d-flex fw-semibold align-items-center">
                        {targetingCardGraphLoading ? (
                          <Skeleton.Input size="small" active block />
                        ) : targetingCardGraphData?.total_ad_spends?.Phrase ? (
                          <>
                            {/* <div
                              className="bullet w-10px h-4px rounded-2  me-3"
                              style={{ background: "#ff9f80" }}
                            /> */}
                            <div className="text-gray-700 flex-grow-1 me-3">
                              Phrase
                            </div>
                            <div className="fw-bolder text-gray-800 fs-6 text-xxl-end">
                              {currency_sign}
                              {NumberWithCommas(
                                targetingCardGraphData?.total_ad_spends
                                  ?.Phrase || 0
                              )}
                            </div>
                          </>
                        ) : (
                          ""
                        )}
                      </div>
                      {targetingCardGraphData?.total_ad_spends?.Phrase ? (
                        <div className="separator separator-dashed my-1" />
                      ) : (
                        ""
                      )}
                      <div className="d-flex fw-semibold align-items-center">
                        {targetingCardGraphLoading ? (
                          <Skeleton.Input size="small" active block />
                        ) : targetingCardGraphData?.total_ad_spends?.Exact ? (
                          <>
                            {/* <div
                              className="bullet w-10px h-4px rounded-2  me-3"
                              style={{ background: "#ffcccb" }}
                            /> */}
                            <div className="text-gray-700 flex-grow-1 me-3">
                              Exact
                            </div>
                            <div className="fw-bolder text-gray-800 fs-6 text-xxl-end">
                              {currency_sign}
                              {NumberWithCommas(
                                targetingCardGraphData?.total_ad_spends
                                  ?.Exact || 0
                              )}
                            </div>
                          </>
                        ) : (
                          ""
                        )}
                      </div>
                      {targetingCardGraphData?.total_ad_spends?.Exact ? (
                        <div className="separator separator-dashed my-1" />
                      ) : (
                        ""
                      )}
                      <div className="d-flex fw-semibold align-items-center">
                        {targetingCardGraphLoading ? (
                          <Skeleton.Input size="small" active block />
                        ) : targetingCardGraphData?.total_ad_spends?.Broad ? (
                          <>
                            {/* <div
                              className="bullet w-10px h-4px rounded-2  me-3"
                              style={{ background: "#e68585" }}
                            /> */}
                            <div className="text-gray-700 flex-grow-1 me-3">
                              Broad
                            </div>
                            <div className="fw-bolder text-gray-800 fs-6 text-xxl-end">
                              {currency_sign}
                              {NumberWithCommas(
                                targetingCardGraphData?.total_ad_spends
                                  ?.Broad || 0
                              )}
                            </div>
                          </>
                        ) : (
                          ""
                        )}
                      </div>
                      {targetingCardGraphData?.total_ad_spends?.Broad ? (
                        // <div className="separator separator-dashed my-1" />
                        <></>
                      ) : (
                        ""
                      )}
                    </div>
                  </div>
                </div>
              </div>
              {/*begin::Body*/}
            </>
          </div>
        </div>
        <div className="col-lg-6">
          <div
            className="card card-xxl-stretch mb-5"
            style={{ border: "1px dashed #f0a6a2" }}
          >
            {/*begin::Header*/}
            <div className="card-header border-bottom-0 min-h-45px">
              <h3 className="card-title align-items-start flex-column">
                <span className="card-label fw-bolder text-dark">
                  Total Ad Sales
                </span>
                {/* <span class="text-gray-400 pt-1 fw-semibold fs-6">Sales analytics by SKU</span> */}
              </h3>
              <div className="card-toolbar">
                <span className="text-gray-800 fw-bolder text-hover-primary fs-2">
                  {targetingCardGraphLoading ? (
                    <Skeleton.Button active />
                  ) : (
                    <>
                      {currency_sign}{" "}
                      {NumberWithCommas(
                        targetingCardGraphData?.all_performance_total
                          ?.all_ad_sales_total
                      )}
                    </>
                  )}
                </span>
              </div>
            </div>
            {/*end::Header*/}
            {/*begin::Body*/}
            <div className="card-body " style={{ padding: "3px 10px" }}>
              <div className=" d-flex justify-content-between align-items-center">
                <div className="pe-2" style={{ width: "65%" }}>
                  <div className="mt-0 " id="chart-new">
                    {/* {getMyChart(totalAdSalesLabel, totalAdSales)} */}
                    {
                      targetingCardGraphLoading ? (
                        <>
                          <Skeleton.Node
                            active
                            style={{ fontSize: "35px", color: "#a0a0a0" }}
                          />
                        </>
                      ) : (
                        // totalAdSalesLabel?.length != 0 &&
                        //   totalAdSales?.length !== 0 ? (
                        <div>{getMyChart(totalAdSalesLabel, totalAdSales)}</div>
                      )
                      // ) : (
                      //   NoDataChart()
                      // )
                    }
                  </div>
                </div>
                <div
                  className=""
                  style={{
                    width: "35%",
                    borderLeft: "1px dashed #f0a6a2",
                    paddingLeft: "10px",
                  }}
                >
                  {/* <div className="border border-dashed border-gray-300 p-2 rounded mb-4 d-flex w-100 align-items-center justify-content-between">
                  <div className="me-4">
                    <span className="badge badge-light-success d-flex align-items-center w-100 ms-2 fs-8 fw-bolder text-green">
                      + 84.57%{" "}
                      <i className="ki-outline ki-arrow-up fs-3 text-green" />
                    </span>
                  </div>
                  <span className="fw-bold fs-6 d-flex text-start text-dark ps-0">
                    $2,539.56
                  </span>
                </div> */}
                  <div className="d-flex flex-column ">
                    {!targetingCardGraphLoading &&
                    targetingCardGraphData?.total_ad_sales?.length == 0 ? (
                      <NoData
                        height="200px"
                        type="new"
                        customWidth="70px"
                        customFontSize="12px"
                      />
                    ) : (
                      ""
                    )}
                    <div className="d-flex fw-semibold align-items-center">
                      {targetingCardGraphLoading ? (
                        <Skeleton.Input size="small" active block />
                      ) : targetingCardGraphData?.total_ad_sales?.Auto ? (
                        <>
                          {/* <div
                            className="bullet w-10px h-4px rounded-2  me-3"
                            style={{ background: "#b3001b" }}
                          /> */}
                          <div className="text-gray-700 flex-grow-1 me-3">
                            Auto
                          </div>
                          <div className="fw-bolder text-gray-800 fs-6 text-xxl-end">
                            {currency_sign}
                            {NumberWithCommas(
                              targetingCardGraphData?.total_ad_sales?.Auto || 0
                            )}
                          </div>
                        </>
                      ) : (
                        ""
                      )}
                    </div>
                    {targetingCardGraphData?.total_ad_sales?.Auto ? (
                      <div className="separator separator-dashed my-1" />
                    ) : (
                      ""
                    )}
                    <div className="d-flex fw-semibold align-items-center">
                      {targetingCardGraphLoading ? (
                        <Skeleton.Input size="small" active block />
                      ) : targetingCardGraphData?.total_ad_sales?.PAT ? (
                        <>
                          {/* <div
                            className="bullet w-10px h-4px rounded-2  me-3"
                            style={{ background: "#ff6f61" }}
                          /> */}
                          <div className="text-gray-700 flex-grow-1 me-3">
                            PAT
                          </div>
                          <div className="fw-bolder text-gray-800 fs-6 text-xxl-end">
                            {currency_sign}{" "}
                            {NumberWithCommas(
                              targetingCardGraphData?.total_ad_sales?.PAT || 0
                            )}
                          </div>
                        </>
                      ) : (
                        ""
                      )}
                    </div>
                    {targetingCardGraphData?.total_ad_sales?.PAT ? (
                      <div className="separator separator-dashed my-1" />
                    ) : (
                      ""
                    )}
                    <div className="d-flex fw-semibold align-items-center">
                      {targetingCardGraphLoading ? (
                        <Skeleton.Input size="small" active block />
                      ) : targetingCardGraphData?.total_ad_sales?.Phrase ? (
                        <>
                          {/* <div
                            className="bullet w-10px h-4px rounded-2  me-3"
                            style={{ background: "#ff9f80" }}
                          /> */}
                          <div className="text-gray-700 flex-grow-1 me-3">
                            Phrase
                          </div>
                          <div className="fw-bolder text-gray-800 fs-6 text-xxl-end">
                            {currency_sign}
                            {NumberWithCommas(
                              targetingCardGraphData?.total_ad_sales?.Phrase ||
                                0
                            )}
                          </div>
                        </>
                      ) : (
                        ""
                      )}
                    </div>
                    {targetingCardGraphData?.total_ad_sales?.Phrase ? (
                      <div className="separator separator-dashed my-1" />
                    ) : (
                      ""
                    )}
                    <div className="d-flex fw-semibold align-items-center">
                      {targetingCardGraphLoading ? (
                        <Skeleton.Input size="small" active block />
                      ) : targetingCardGraphData?.total_ad_sales?.Exact ? (
                        <>
                          {/* <div
                            className="bullet w-10px h-4px rounded-2  me-3"
                            style={{ background: "#ffcccb" }}
                          /> */}
                          <div className="text-gray-700 flex-grow-1 me-3">
                            Exact
                          </div>
                          <div className="fw-bolder text-gray-800 fs-6 text-xxl-end">
                            {currency_sign}
                            {NumberWithCommas(
                              targetingCardGraphData?.total_ad_sales?.Exact || 0
                            )}
                          </div>
                        </>
                      ) : (
                        ""
                      )}
                    </div>
                    {targetingCardGraphData?.total_ad_sales?.Exact ? (
                      <div className="separator separator-dashed my-1" />
                    ) : (
                      ""
                    )}
                    <div className="d-flex fw-semibold align-items-center">
                      {targetingCardGraphLoading ? (
                        <Skeleton.Input size="small" active block />
                      ) : targetingCardGraphData?.total_ad_sales?.Broad ? (
                        <>
                          {/* <div
                            className="bullet w-10px h-4px rounded-2  me-3"
                            style={{ background: "#e68585" }}
                          /> */}
                          <div className="text-gray-700 flex-grow-1 me-3">
                            Broad
                          </div>
                          <div className="fw-bolder text-gray-800 fs-6 text-xxl-end">
                            {currency_sign}
                            {NumberWithCommas(
                              targetingCardGraphData?.total_ad_sales?.Broad || 0
                            )}
                          </div>
                        </>
                      ) : (
                        ""
                      )}
                    </div>
                    {targetingCardGraphData?.total_ad_sales?.Broad ? <></> : ""}
                    {/* <div className="separator separator-dashed my-1" /> */}
                  </div>
                </div>
              </div>
            </div>
            {/*begin::Body*/}
          </div>
        </div>
        <div className="col-lg-6">
          <div
            className="card card-xxl-stretch mb-5"
            style={{ border: "1px dashed #f0a6a2" }}
          >
            {/*begin::Header*/}
            <div className="card-header border-bottom-0 min-h-45px">
              <h3 className="card-title align-items-start flex-column">
                <span className="card-label fw-bolder text-dark">
                  Total ACoS
                </span>
                {/* <span class="text-gray-400 pt-1 fw-semibold fs-6">Sales analytics by SKU</span> */}
              </h3>
              <div className="card-toolbar">
                <span className="text-gray-800 fw-bolder text-hover-primary fs-2">
                  {targetingCardGraphLoading ? (
                    <Skeleton.Button active />
                  ) : (
                    <>
                      {NumberWithCommas(
                        targetingCardGraphData?.all_performance_total
                          ?.all_ad_acos_total
                      )}{" "}
                      %
                    </>
                  )}
                </span>
              </div>
            </div>
            {/*end::Header*/}
            {/*begin::Body*/}
            <div className="card-body " style={{ padding: "3px 10px" }}>
              <div className=" d-flex justify-content-between align-items-center">
                <div className="pe-2" style={{ width: "65%" }}>
                  <div className="mt-0 " id="chart-new">
                    {/* {getMyChart(totalACOSLabel, totalACOS)} */}
                    {
                      targetingCardGraphLoading ? (
                        <>
                          <Skeleton.Node
                            active
                            style={{ fontSize: "35px", color: "#a0a0a0" }}
                          />
                        </>
                      ) : (
                        // totalACOSLabel?.length != 0 &&
                        //   totalACOS?.length !== 0 ? (
                        <div>{getMyChart(totalACOSLabel, totalACOS)}</div>
                      )
                      // ) : (
                      //   NoDataChart()
                      // )
                    }
                  </div>
                </div>
                <div
                  className=""
                  style={{
                    width: "35%",
                    borderLeft: "1px dashed #f0a6a2",
                    paddingLeft: "10px",
                  }}
                >
                  {/* <div className="border border-dashed border-gray-300 p-2 rounded mb-4 d-flex w-100 align-items-center justify-content-between">
                  <div className="me-4">
                    <span className="badge badge-light-success d-flex align-items-center w-100 ms-2 fs-8 fw-bolder text-green">
                      + 84.57%{" "}
                      <i className="ki-outline ki-arrow-up fs-3 text-green" />
                    </span>
                  </div>
                  <span className="fw-bold fs-6 d-flex text-start text-dark ps-0">
                    2,539.56 %
                  </span>
                </div> */}
                  <div className="d-flex flex-column ">
                    {!targetingCardGraphLoading &&
                    targetingCardGraphData?.total_acos?.length == 0 ? (
                      <NoData
                        height="200px"
                        type="new"
                        customWidth="70px"
                        customFontSize="12px"
                      />
                    ) : (
                      ""
                    )}
                    <div className="d-flex fw-semibold align-items-center">
                      {targetingCardGraphLoading ? (
                        <Skeleton.Input size="small" active block />
                      ) : targetingCardGraphData?.total_acos?.Auto ? (
                        <>
                          {/* <div
                            className="bullet w-10px h-4px rounded-2  me-3"
                            style={{ background: "#b3001b" }}
                          /> */}
                          <div className="text-gray-700 flex-grow-1 me-3">
                            Auto
                          </div>
                          <div className="fw-bolder text-gray-800 fs-6 text-xxl-end">
                            {NumberWithCommas(
                              targetingCardGraphData?.total_acos?.Auto || 0
                            )}{" "}
                            %
                          </div>
                        </>
                      ) : (
                        ""
                      )}
                    </div>
                    {targetingCardGraphData?.total_acos?.Auto ? (
                      <div className="separator separator-dashed my-1" />
                    ) : (
                      ""
                    )}
                    <div className="d-flex fw-semibold align-items-center">
                      {targetingCardGraphLoading ? (
                        <Skeleton.Input size="small" active block />
                      ) : targetingCardGraphData?.total_acos?.PAT ? (
                        <>
                          {/* <div
                            className="bullet w-10px h-4px rounded-2  me-3"
                            style={{ background: "#ff6f61" }}
                          /> */}
                          <div className="text-gray-700 flex-grow-1 me-3">
                            PAT
                          </div>
                          <div className="fw-bolder text-gray-800 fs-6 text-xxl-end">
                            {NumberWithCommas(
                              targetingCardGraphData?.total_acos?.PAT || 0
                            )}{" "}
                            %
                          </div>
                        </>
                      ) : (
                        ""
                      )}
                    </div>
                    {targetingCardGraphData?.total_acos?.PAT ? (
                      <div className="separator separator-dashed my-1" />
                    ) : (
                      ""
                    )}
                    <div className="d-flex fw-semibold align-items-center">
                      {targetingCardGraphLoading ? (
                        <Skeleton.Input size="small" active block />
                      ) : targetingCardGraphData?.total_acos?.Phrase ? (
                        <>
                          {/* <div
                            className="bullet w-10px h-4px rounded-2  me-3"
                            style={{ background: "#ff9f80" }}
                          /> */}
                          <div className="text-gray-700 flex-grow-1 me-3">
                            Phrase
                          </div>
                          <div className="fw-bolder text-gray-800 fs-6 text-xxl-end">
                            {NumberWithCommas(
                              targetingCardGraphData?.total_acos?.Phrase || 0
                            )}{" "}
                            %
                          </div>
                        </>
                      ) : (
                        ""
                      )}
                    </div>
                    {targetingCardGraphData?.total_acos?.Phrase ? (
                      <div className="separator separator-dashed my-1" />
                    ) : (
                      ""
                    )}
                    <div className="d-flex fw-semibold align-items-center">
                      {targetingCardGraphLoading ? (
                        <Skeleton.Input size="small" active block />
                      ) : targetingCardGraphData?.total_acos?.Exact ? (
                        <>
                          {/* <div
                            className="bullet w-10px h-4px rounded-2  me-3"
                            style={{ background: "#ffcccb" }}
                          /> */}
                          <div className="text-gray-700 flex-grow-1 me-3">
                            Exact
                          </div>
                          <div className="fw-bolder text-gray-800 fs-6 text-xxl-end">
                            {NumberWithCommas(
                              targetingCardGraphData?.total_acos?.Exact || 0
                            )}{" "}
                            %
                          </div>
                        </>
                      ) : (
                        ""
                      )}
                    </div>
                    {targetingCardGraphData?.total_acos?.Exact ? (
                      <div className="separator separator-dashed my-1" />
                    ) : (
                      ""
                    )}
                    <div className="d-flex fw-semibold align-items-center">
                      {targetingCardGraphLoading ? (
                        <Skeleton.Input size="small" active block />
                      ) : targetingCardGraphData?.total_acos?.Broad ? (
                        <>
                          {/* <div
                            className="bullet w-10px h-4px rounded-2  me-3"
                            style={{ background: "#e68585" }}
                          /> */}
                          <div className="text-gray-700 flex-grow-1 me-3">
                            Broad
                          </div>
                          <div className="fw-bolder text-gray-800 fs-6 text-xxl-end">
                            {NumberWithCommas(
                              targetingCardGraphData?.total_acos?.Broad || 0
                            )}{" "}
                            %
                          </div>
                        </>
                      ) : (
                        ""
                      )}
                    </div>
                    {targetingCardGraphData?.total_acos?.Broad ? <></> : ""}
                    {/* <div className="separator separator-dashed my-1" /> */}
                  </div>
                </div>
              </div>
            </div>
            {/*begin::Body*/}
          </div>
        </div>
        <div className="col-lg-6">
          <div
            className="card card-xxl-stretch mb-5"
            style={{ border: "1px dashed #f0a6a2" }}
          >
            {/*begin::Header*/}
            <div className="card-header border-bottom-0 min-h-45px">
              <h3 className="card-title align-items-start flex-column">
                <span className="card-label fw-bolder text-dark">
                  Total RoAS
                </span>
                {/* <span class="text-gray-400 pt-1 fw-semibold fs-6">Sales analytics by SKU</span> */}
              </h3>
              <div className="card-toolbar">
                <span className="text-gray-800 fw-bolder text-hover-primary fs-2">
                  {targetingCardGraphLoading ? (
                    <Skeleton.Button active />
                  ) : (
                    <>
                      {NumberWithCommas(
                        targetingCardGraphData?.all_performance_total
                          ?.all_ad_roas_total
                      )}
                    </>
                  )}
                </span>
              </div>
            </div>
            {/*end::Header*/}
            {/*begin::Body*/}
            <div className="card-body " style={{ padding: "3px 10px" }}>
              <div className=" d-flex justify-content-between align-items-center">
                <div className="pe-2" style={{ width: "65%" }}>
                  <div className="mt-0 " id="chart-new">
                    {/* {getMyChart(totalROASLabel, totalROAS)} */}
                    {
                      targetingCardGraphLoading ? (
                        <>
                          <Skeleton.Node
                            active
                            style={{ fontSize: "35px", color: "#a0a0a0" }}
                          />
                        </>
                      ) : (
                        // totalROASLabel?.length != 0 &&
                        //   totalROAS?.length !== 0 ? (
                        <div>{getMyChart(totalROASLabel, totalROAS)}</div>
                      )
                      // ) : (
                      //   NoDataChart()
                      // )
                    }
                  </div>
                </div>
                <div
                  className=""
                  style={{
                    width: "35%",
                    borderLeft: "1px dashed #f0a6a2",
                    paddingLeft: "10px",
                  }}
                >
                  {/* <div className="border border-dashed border-gray-300 p-2 rounded mb-4 d-flex w-100 align-items-center justify-content-between">
                  <div className="me-4">
                    <span className="badge badge-light-success d-flex align-items-center w-100 ms-2 fs-8 fw-bolder text-green">
                      + 84.57%{" "}
                      <i className="ki-outline ki-arrow-up fs-3 text-green" />
                    </span>
                  </div>
                  <span className="fw-bold fs-6 d-flex text-start text-dark ps-0">
                    $2,539.56
                  </span>
                </div> */}
                  <div className="d-flex flex-column ">
                    {!targetingCardGraphLoading &&
                    targetingCardGraphData?.total_roas?.length == 0 ? (
                      <NoData
                        height="200px"
                        type="new"
                        customWidth="70px"
                        customFontSize="12px"
                      />
                    ) : (
                      ""
                    )}
                    <div className="d-flex fw-semibold align-items-center">
                      {targetingCardGraphLoading ? (
                        <Skeleton.Input size="small" active block />
                      ) : targetingCardGraphData?.total_roas?.Auto ? (
                        <>
                          {/* <div
                            className="bullet w-10px h-4px rounded-2  me-3"
                            style={{ background: "#b3001b" }}
                          /> */}
                          <div className="text-gray-700 flex-grow-1 me-3">
                            Auto
                          </div>
                          <div className="fw-bolder text-gray-800 fs-6 text-xxl-end">
                            {NumberWithCommas(
                              targetingCardGraphData?.total_roas?.Auto || 0
                            )}
                          </div>
                        </>
                      ) : (
                        ""
                      )}
                    </div>
                    {targetingCardGraphData?.total_roas?.Auto ? (
                      <div className="separator separator-dashed my-1" />
                    ) : (
                      ""
                    )}
                    <div className="d-flex fw-semibold align-items-center">
                      {targetingCardGraphLoading ? (
                        <Skeleton.Input size="small" active block />
                      ) : targetingCardGraphData?.total_roas?.PAT ? (
                        <>
                          {/* <div
                            className="bullet w-10px h-4px rounded-2  me-3"
                            style={{ background: "#ff6f61" }}
                          /> */}
                          <div className="text-gray-700 flex-grow-1 me-3">
                            PAT
                          </div>
                          <div className="fw-bolder text-gray-800 fs-6 text-xxl-end">
                            {NumberWithCommas(
                              targetingCardGraphData?.total_roas?.PAT || 0
                            )}
                          </div>
                        </>
                      ) : (
                        ""
                      )}
                    </div>
                    {targetingCardGraphData?.total_roas?.PAT ? (
                      <div className="separator separator-dashed my-1" />
                    ) : (
                      ""
                    )}
                    <div className="d-flex fw-semibold align-items-center">
                      {targetingCardGraphLoading ? (
                        <Skeleton.Input size="small" active block />
                      ) : targetingCardGraphData?.total_roas?.Phrase ? (
                        <>
                          {/* <div
                            className="bullet w-10px h-4px rounded-2  me-3"
                            style={{ background: "#ff9f80" }}
                          /> */}
                          <div className="text-gray-700 flex-grow-1 me-3">
                            Phrase
                          </div>
                          <div className="fw-bolder text-gray-800 fs-6 text-xxl-end">
                            {NumberWithCommas(
                              targetingCardGraphData?.total_roas?.Phrase || 0
                            )}
                          </div>
                        </>
                      ) : (
                        ""
                      )}
                    </div>
                    {targetingCardGraphData?.total_roas?.Phrase ? (
                      <div className="separator separator-dashed my-1" />
                    ) : (
                      ""
                    )}
                    <div className="d-flex fw-semibold align-items-center">
                      {targetingCardGraphLoading ? (
                        <Skeleton.Input size="small" active block />
                      ) : targetingCardGraphData?.total_roas?.Exact ? (
                        <>
                          {/* <div
                            className="bullet w-10px h-4px rounded-2  me-3"
                            style={{ background: "#ffcccb" }}
                          /> */}
                          <div className="text-gray-700 flex-grow-1 me-3">
                            Exact
                          </div>
                          <div className="fw-bolder text-gray-800 fs-6 text-xxl-end">
                            {NumberWithCommas(
                              targetingCardGraphData?.total_roas?.Exact || 0
                            )}{" "}
                          </div>
                        </>
                      ) : (
                        ""
                      )}
                    </div>
                    {targetingCardGraphData?.total_roas?.Exact ? (
                      <div className="separator separator-dashed my-1" />
                    ) : (
                      ""
                    )}
                    <div className="d-flex fw-semibold align-items-center">
                      {targetingCardGraphLoading ? (
                        <Skeleton.Input size="small" active block />
                      ) : targetingCardGraphData?.total_roas?.Broad ? (
                        <>
                          {/* <div
                            className="bullet w-10px h-4px rounded-2  me-3"
                            style={{ background: "#e68585" }}
                          /> */}
                          <div className="text-gray-700 flex-grow-1 me-3">
                            Broad
                          </div>
                          <div className="fw-bolder text-gray-800 fs-6 text-xxl-end">
                            {NumberWithCommas(
                              targetingCardGraphData?.total_roas?.Broad || 0
                            )}{" "}
                          </div>
                        </>
                      ) : (
                        ""
                      )}
                    </div>
                    {targetingCardGraphData?.total_roas?.Broad ? <></> : ""}
                    {/* <div className="separator separator-dashed my-1" /> */}
                  </div>
                </div>
              </div>
            </div>
            {/*begin::Body*/}
          </div>
        </div>
      </div>
      {/* <div className="separator separator-dashed my-1" /> */}
      {targetingCardLoading && (
        <div className="row g-5 gy-7">
          {/*begin::Col*/}
          <div className="col-sm-4 col-xl-2 mb-xl-5">
            <Skeleton.Button active block style={{ height: "150px" }} />
          </div>
          <div className="col-sm-4 col-xl-2 mb-xl-5">
            <Skeleton.Button active block style={{ height: "150px" }} />
          </div>
          <div className="col-sm-4 col-xl-2 mb-xl-5">
            <Skeleton.Button active block style={{ height: "150px" }} />
          </div>
          <div className="col-sm-4 col-xl-2 mb-xl-5">
            <Skeleton.Button active block style={{ height: "150px" }} />
          </div>
          <div className="col-sm-4 col-xl-2 mb-xl-5">
            <Skeleton.Button active block style={{ height: "150px" }} />
          </div>
          <div className="col-sm-4 col-xl-2 mb-xl-5">
            <Skeleton.Button active block style={{ height: "150px" }} />
          </div>
        </div>
      )}
      {!targetingCardLoading && (
        <div className="row d-none">
          {/*begin::Col*/}
          <div className="col-sm-4 col-xl-2 mb-5">
            {/*begin::Card widget 2*/}
            <div
              className="card h-lg-100"
              style={{
                border: "3px solid #9185cd",
                // background: "linear-gradient(180deg, #9488cf24, #fff, #ffff)",
              }}
            >
              {/*begin::Body*/}
              <span
              // id={Math.random()}
              // to={{
              //   pathname:
              //     "/advertisement/advertisement-dashboard/active-keywords",
              //   search: `?start_date=${start_date}&end_date=${end_date}`,
              // }}
              // target="_blank"
              >
                {" "}
                <div className="card-body d-flex justify-content-between align-items-start flex-column p-5 active-keyword-div">
                  {/*begin::Icon*/}
                  <div className="m-0">
                    <i
                      className="ki-duotone ki-tablet-ok fs-2hx "
                      style={{ color: "#9185cd" }}
                    >
                      <span className="path1" />
                      <span className="path2" />
                      <span className="path3" />
                    </i>
                  </div>
                  {/*end::Icon*/}
                  {/*begin::Section*/}
                  <div className="d-flex flex-column my-5">
                    {/*begin::Number*/}
                    <span className="fw-bold fs-2x text-gray-800 lh-1 ls-n2">
                      {NumberWithCommas(
                        targetingCardData?.Keyword?.Active?.current || 0
                      )}
                    </span>
                    {/*end::Number*/}
                    {/*begin::Follower*/}
                    <div
                      className="m-0"
                      onClick={() => {
                        message.destroy();
                        message.loading("Loading...", 0);
                        GetActiveKeywords();
                      }}
                    >
                      <span className="fw-semibold fs-6 active-keyword-hover cursor-pointer">
                        Active Keywords
                      </span>
                    </div>
                    {/*end::Follower*/}
                  </div>
                  {/*end::Section*/}
                  {/*begin::Badge*/}

                  {targetingCardData?.Keyword?.Active?.change_percentage > 0 ? (
                    <span className="badge badge-light-success fs-8">
                      <i className="ki-outline ki-arrow-up fs-5 text-green ms-n1" />
                      {NumberWithCommas(
                        targetingCardData?.Keyword?.Active?.change_percentage ||
                          0
                      )}
                      %
                    </span>
                  ) : (
                    <span className="badge badge-light-danger fs-8">
                      <i className="ki-outline ki-arrow-down fs-5 text-danger ms-n1" />
                      {NumberWithCommas(
                        targetingCardData?.Keyword?.Active?.change_percentage ||
                          0
                      )}
                      %
                    </span>
                  )}
                  {/*end::Badge*/}
                </div>
              </span>

              {/*end::Body*/}
            </div>
            {/*end::Card widget 2*/}
          </div>
          {/*end::Col*/}
          {/*begin::Col*/}
          <div className="col-sm-4 col-xl-2 mb-5">
            {/*begin::Card widget 2*/}
            <div
              className="card h-lg-100"
              style={{
                border: "3px solid #c483cc",
                // background: "linear-gradient(180deg, #c483cc24, #fff, #ffff)",
              }}
            >
              {/*begin::Body*/}
              <div className="card-body d-flex justify-content-between align-items-start flex-column p-5">
                {/*begin::Icon*/}
                <div className="m-0">
                  <i
                    className="ki-duotone ki-tablet-delete fs-2hx"
                    style={{ color: "#c483cc" }}
                  >
                    <span className="path1" />
                    <span className="path2" />
                    <span className="path3" />
                    <span className="path4" />
                  </i>
                </div>
                {/*end::Icon*/}
                {/*begin::Section*/}
                <div className="d-flex flex-column my-5">
                  {/*begin::Number*/}
                  <span className="fw-bold fs-2x text-gray-800 lh-1 ls-n2">
                    {NumberWithCommas(
                      targetingCardData?.Keyword?.InActive?.current || 0
                    )}
                  </span>
                  {/*end::Number*/}
                  {/*begin::Follower*/}
                  <div className="m-0">
                    <span className="fw-semibold fs-6 text-gray-600">
                      Inactive Keywords
                    </span>
                  </div>
                  {/*end::Follower*/}
                </div>
                {/*end::Section*/}
                {/*begin::Badge*/}
                {targetingCardData?.Keyword?.InActive?.change_percentage > 0 ? (
                  <span className="badge badge-light-success fs-8">
                    <i className="ki-outline ki-arrow-up fs-5 text-green ms-n1" />
                    {NumberWithCommas(
                      targetingCardData?.Keyword?.InActive?.change_percentage ||
                        0
                    )}
                    %
                  </span>
                ) : (
                  <span className="badge badge-light-danger fs-8">
                    <i className="ki-outline ki-arrow-down fs-5 text-danger ms-n1" />
                    {NumberWithCommas(
                      targetingCardData?.Keyword?.InActive?.change_percentage ||
                        0
                    )}
                    %
                  </span>
                )}

                {/*end::Badge*/}
              </div>
              {/*end::Body*/}
            </div>
            {/*end::Card widget 2*/}
          </div>
          {/*end::Col*/}
          {/*begin::Col*/}
          <div className="col-sm-4 col-xl-2 mb-5">
            {/*begin::Card widget 2*/}
            <div
              className="card h-lg-100"
              style={{
                border: "3px solid #cc8397",
                // background: "linear-gradient(180deg, #cc839724, #fff, #ffff)",
              }}
            >
              {/*begin::Body*/}
              <div className="card-body d-flex justify-content-between align-items-start flex-column p-5">
                {/*begin::Icon*/}
                <div className="m-0">
                  <i
                    className="ki-duotone ki-shield-tick fs-2hx "
                    style={{ color: "#cc8397" }}
                  >
                    <span className="path1" />
                    <span className="path2" />
                    <span className="path3" />
                  </i>
                </div>
                {/*end::Icon*/}
                {/*begin::Section*/}
                <div className="d-flex flex-column my-5">
                  {/*begin::Number*/}
                  <span className="fw-bold fs-2x text-gray-800 lh-1 ls-n2">
                    {NumberWithCommas(
                      targetingCardData?.PAT?.Active?.current || 0
                    )}
                  </span>
                  {/*end::Number*/}
                  {/*begin::Follower*/}
                  <div className="m-0">
                    <span className="fw-semibold fs-6 text-gray-600">
                      Active PAT
                    </span>
                  </div>
                  {/*end::Follower*/}
                </div>
                {/*end::Section*/}
                {/*begin::Badge*/}

                {targetingCardData?.PAT?.Active?.change_percentage > 0 ? (
                  <span className="badge badge-light-success fs-8">
                    <i className="ki-outline ki-arrow-up fs-5 text-green ms-n1" />
                    {NumberWithCommas(
                      targetingCardData?.PAT?.Active?.change_percentage || 0
                    )}
                    %
                  </span>
                ) : (
                  <span className="badge badge-light-danger fs-8">
                    <i className="ki-outline ki-arrow-down fs-5 text-danger ms-n1" />
                    {NumberWithCommas(
                      targetingCardData?.PAT?.Active?.change_percentage || 0
                    )}
                    %
                  </span>
                )}
                {/*end::Badge*/}
              </div>
              {/*end::Body*/}
            </div>
            {/*end::Card widget 2*/}
          </div>
          {/*end::Col*/}
          {/*begin::Col*/}
          <div className="col-sm-4 col-xl-2 mb-5">
            {/*begin::Card widget 2*/}
            <div
              className="card h-lg-100"
              style={{
                border: "3px solid #ccb083",
                // background: "linear-gradient(180deg, #ccb08324, #fff, #ffff)",
              }}
            >
              {/*begin::Body*/}
              <div className="card-body d-flex justify-content-between align-items-start flex-column p-5">
                {/*begin::Icon*/}
                <div className="m-0">
                  <i
                    className="ki-duotone ki-shield-cross fs-2hx"
                    style={{ color: "#ccb083" }}
                  >
                    <span className="path1" />
                    <span className="path2" />
                    <span className="path3" />
                  </i>
                </div>
                {/*end::Icon*/}
                {/*begin::Section*/}
                <div className="d-flex flex-column my-5">
                  {/*begin::Number*/}
                  <span className="fw-bold fs-2x text-gray-800 lh-1 ls-n2">
                    {NumberWithCommas(
                      targetingCardData?.PAT?.InActive?.current || 0
                    )}
                  </span>
                  {/*end::Number*/}
                  {/*begin::Follower*/}
                  <div className="m-0">
                    <span className="fw-semibold fs-6 text-gray-600">
                      Inactive PAT
                    </span>
                  </div>
                  {/*end::Follower*/}
                </div>
                {/*end::Section*/}
                {/*begin::Badge*/}
                {targetingCardData?.PAT?.InActive?.change_percentage > 0 ? (
                  <span className="badge badge-light-success fs-8">
                    <i className="ki-outline ki-arrow-up fs-5 text-green ms-n1" />
                    {NumberWithCommas(
                      targetingCardData?.PAT?.InActive?.change_percentage || 0
                    )}
                    %
                  </span>
                ) : (
                  <span className="badge badge-light-danger fs-8">
                    <i className="ki-outline ki-arrow-down fs-5 text-danger ms-n1" />
                    {NumberWithCommas(
                      targetingCardData?.PAT?.InActive?.change_percentage || 0
                    )}
                    %
                  </span>
                )}

                {/*end::Badge*/}
              </div>
              {/*end::Body*/}
            </div>
            {/*end::Card widget 2*/}
          </div>
          {/*end::Col*/}
          {/*begin::Col*/}
          <div className="col-sm-4 col-xl-2 mb-5">
            {/*begin::Card widget 2*/}
            <div
              className="card h-lg-100"
              style={{
                border: "3px solid #c4cc83",
                // background: "linear-gradient(180deg, #c4cc8324, #fff, #ffff)",
              }}
            >
              {/*begin::Body*/}
              <div className="card-body d-flex justify-content-between align-items-start flex-column p-5">
                {/*begin::Icon*/}
                <div className="m-0">
                  <i
                    className="ki-duotone ki-check-circle fs-2hx"
                    style={{ color: "#c4cc83" }}
                  >
                    <span className="path1" />
                    <span className="path2" />
                    <span className="path3" />
                  </i>
                </div>
                {/*end::Icon*/}
                {/*begin::Section*/}
                <div className="d-flex flex-column my-5">
                  {/*begin::Number*/}
                  <span className="fw-bold fs-2x text-gray-800 lh-1 ls-n2">
                    {NumberWithCommas(
                      targetingCardData?.Auto?.Active?.current || 0
                    )}
                  </span>
                  {/*end::Number*/}
                  {/*begin::Follower*/}
                  <div className="m-0">
                    <span className="fw-semibold fs-6 text-gray-600">
                      Active Auto
                    </span>
                  </div>
                  {/*end::Follower*/}
                </div>
                {/*end::Section*/}
                {/*begin::Badge*/}
                {targetingCardData?.Auto?.Active?.change_percentage > 0 ? (
                  <span className="badge badge-light-success fs-8">
                    <i className="ki-outline ki-arrow-up fs-5 text-green ms-n1" />
                    {NumberWithCommas(
                      targetingCardData?.Auto?.Active?.change_percentage || 0
                    )}
                    %
                  </span>
                ) : (
                  <span className="badge badge-light-danger fs-8">
                    <i className="ki-outline ki-arrow-down fs-5 text-danger ms-n1" />
                    {NumberWithCommas(
                      targetingCardData?.Auto?.Active?.change_percentage || 0
                    )}
                    %
                  </span>
                )}

                {/*end::Badge*/}
              </div>
              {/*end::Body*/}
            </div>
            {/*end::Card widget 2*/}
          </div>
          {/*end::Col*/}
          {/*begin::Col*/}
          <div className="col-sm-4 col-xl-2 mb-5 ">
            {/*begin::Card widget 2*/}
            <div
              className="card h-lg-100"
              style={{
                border: "3px solid #84cc94",
                // background: "linear-gradient(180deg, #84cc9424, #fff, #ffff)",
              }}
            >
              {/*begin::Body*/}
              <div className="card-body d-flex justify-content-between align-items-start flex-column p-5">
                {/*begin::Icon*/}
                <div className="m-0">
                  <i
                    className="ki-duotone ki-cross-circle fs-2hx"
                    style={{ color: "#84cc94" }}
                  >
                    <span className="path1" />
                    <span className="path2" />
                    <span className="path3" />
                    <span className="path4" />
                  </i>
                </div>
                {/*end::Icon*/}
                {/*begin::Section*/}
                <div className="d-flex flex-column my-5">
                  {/*begin::Number*/}
                  <span className="fw-bold fs-2x text-gray-800 lh-1 ls-n2">
                    {" "}
                    {NumberWithCommas(
                      targetingCardData?.Auto?.InActive?.current || 0
                    )}
                  </span>
                  {/*end::Number*/}
                  {/*begin::Follower*/}
                  <div className="m-0">
                    <span className="fw-semibold fs-6 text-gray-600">
                      Inactive Auto
                    </span>
                  </div>
                  {/*end::Follower*/}
                </div>
                {/*end::Section*/}
                {/*begin::Badge*/}

                {targetingCardData?.Auto?.InActive?.change_percentage > 0 ? (
                  <span className="badge badge-light-success fs-8">
                    <i className="ki-outline ki-arrow-up fs-5 text-green ms-n1" />
                    {NumberWithCommas(
                      targetingCardData?.Auto?.InActive?.change_percentage || 0
                    )}
                    %
                  </span>
                ) : (
                  <span className="badge badge-light-danger fs-8">
                    <i className="ki-outline ki-arrow-down fs-5 text-danger ms-n1" />
                    {NumberWithCommas(
                      targetingCardData?.Auto?.InActive?.change_percentage || 0
                    )}
                    %
                  </span>
                )}

                {/*end::Badge*/}
              </div>
              {/*end::Body*/}
            </div>
            {/*end::Card widget 2*/}
          </div>
          {/*end::Col*/}
        </div>
      )}
    </>
  );
}
