import { Input, message, Popover, Skeleton, Switch, Upload } from "antd";
import React, { useState } from "react";
import Pagination from "../../../../../../component/esf-table/lib/pagination";
import {
  ConvertParams,
  DefaultPerPage,
  DisplayNotification,
  downloadFile,
} from "../../../../../../config";
import { LoadingOutlined } from "@ant-design/icons";
import { MakeApiCall } from "../../../../../../apis/make-api-call";
import ErrorModal from "./error-modal";

const { Dragger } = Upload;
const SPStep2 = (props) => {
  const {
    selected_adgroup_list,
    product_list,
    set_product_list,
    selected_product_list,
    set_selected_product_list,
    all_common_hide_show,
    set_all_common_hide_show,
    all_filters,
    set_all_filters,
    GetProductList,
    product_loading,
    set_product_loading,
    totalPage,
    onPageNo,
    onPerPage,
    selected_marketplace,
  } = props;

  const [localChecked, setLocalChecked] = useState([]);
  const [loading, setLoading] = useState(false);
  const [enter_list_val, set_enter_list_val] = useState("");
  const [isUploading, setIsUploading] = useState(false);
  const [isOpenErrorModal, setIsOpenErrorModal] = useState(false);
  const [inValidAsin, setInValidAsin] = useState([]);
  const GetEnterListProducts = async (data) => {
    const response = await MakeApiCall(
      `advertising/check-product-by-asin`,
      `POST`,
      data,
      true
    );
    if (response?.status) {
      setLoading(false);
      set_enter_list_val("");
      setInValidAsin(response?.data?.asin_not_found);
      const newData = response?.data?.product_list?.map((d) => {
        return { ...d, state: "ENABLED" };
      });
      set_selected_product_list(newData || []);
    } else {
      DisplayNotification("error", "Error", response?.message);
    }
  };

  const customUpload = async (props) => {
    const { file, onSuccess, onError } = props;
    try {
      setIsUploading(true);
      const formData = new FormData();
      formData.append("product_template_file", file);
      formData.append("marketplace_id", selected_marketplace);
      formData.append(
        "product_asins",
        selected_product_list?.map((d) => {
          return d?.asin1;
        })
      );
      const response = await MakeApiCall(
        `advertising/check-product-by-file`,
        `POST`,
        formData,
        true,
        true
      );
      if (response?.status) {
        setIsUploading(false);
        message.destroy();
        DisplayNotification(
          "success",
          "Success",
          "Asin list uploaded successfully"
        );
        const newData = response?.data?.product_list?.map((d) => {
          return { ...d, state: "ENABLED" };
        });
        set_selected_product_list(newData || []);
      } else {
        DisplayNotification("error", "Error", response?.message);
      }
    } catch (error) {
      message.destroy();
      DisplayNotification("error", "Error", error);
    }
  };

  const beforeUpload = (file) => {
    const isExcel =
      file.type === "application/vnd.ms-excel" ||
      file.type ===
        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet";

    if (!isExcel) {
      message.error(`${file.name} is not an excel file`);
    }

    return isExcel || Upload.LIST_IGNORE; // Ignore the file if it's not Excel
  };
  return (
    <>
      <div
        className="flex-column current"
        data-kt-stepper-element="content"
        style={{ minHeight: 500 }}
      >
        <div className="row gy-5 g-xl-5">
          <div className="col-md-12 d-flex">
            <div className="position-relative w-350px">
              {/* <Select
                options={selected_adgroup_list?.map((d) => {
                  return { label: d, value: d };
                })}
               
                style={{ width: "100%" }}
                placeholder="Select ad group"
              /> */}
              {/* <div
                className="border rounded w-100 border-dashed border-primary"
                id=""
              >
                <div style={{ padding: "10px" }}>
                  <div className="d-flex align-items-center w-100">
                    <div className="d-flex flex-column w-100">
                      <span className="fs-6 fw-bold ">
                        {selected_adgroup_list?.[0]}
                      </span>
                    </div>
                  </div>
                </div>
              </div> */}
            </div>
            {/* <div className="position-relative ">
              <button className="btn btn-primary w-40px h-40px ms-3 btn-icon">
                <i className="ki-outline ki-plus fs-2" />
              </button>
            </div> */}
          </div>

          <div className="col-xxl-6">
            <div className="card min-h-450px">
              <div className="card-header card-header-stretch">
                <div className="card-title m-0">
                  <h3 className="fw-bold m-0">Products</h3>
                </div>
                <div className="card-toolbar">
                  <ul className="nav nav-tabs nav-line-tabs nav-stretch fs-6 border-0 nav-line-tabs-2x">
                    <li
                      className="nav-item cursor-pointer "
                      onClick={() => {
                        set_all_common_hide_show({
                          ...all_common_hide_show,
                          add_items: "search",
                        });
                      }}
                    >
                      <span
                        className={`nav-link fw-bold text-gray-800 ${
                          all_common_hide_show?.add_items === "search"
                            ? "active"
                            : ""
                        }`}
                      >
                        Search
                      </span>
                    </li>
                    <li
                      className="nav-item cursor-pointer "
                      onClick={() => {
                        set_all_common_hide_show({
                          ...all_common_hide_show,
                          add_items: "enter_list",
                        });
                      }}
                    >
                      <span
                        className={`nav-link fw-bold text-gray-800 ${
                          all_common_hide_show?.add_items === "enter_list"
                            ? "active"
                            : ""
                        }`}
                      >
                        Enter List
                      </span>
                    </li>
                    <li
                      className="nav-item cursor-pointer "
                      onClick={() => {
                        set_all_common_hide_show({
                          ...all_common_hide_show,
                          add_items: "upload",
                        });
                      }}
                    >
                      <span
                        className={`nav-link fw-bold text-gray-800 ${
                          all_common_hide_show?.add_items === "upload"
                            ? "active"
                            : ""
                        }`}
                      >
                        Upload
                      </span>
                    </li>
                  </ul>
                </div>
              </div>
              <div className="card-body">
                <div className="tab-content" id="myTabContent">
                  <div
                    className={`tab-pane fade  ${
                      all_common_hide_show?.add_items === "search"
                        ? "show active"
                        : ""
                    }`}
                  >
                    <div className="mt-0">
                      <div className="position-relative mb-2">
                        <Input
                          prefix={
                            <i className="ki-outline ki-magnifier fs-2" />
                          }
                          size="large"
                          placeholder="Search by product name or ASIN"
                          onPressEnter={(e) => {
                            const apiObj = {
                              ...all_filters,
                              offset: 1,
                              "per-page": DefaultPerPage,
                              search: e.target.value,
                            };
                            set_all_filters(apiObj);
                            set_product_loading(true);
                            GetProductList(apiObj);
                          }}
                        />
                      </div>

                      {/* <p className="mb-0 mt-3 fs-7 text-primary">
                        Search for products you want to exclude.
                      </p> */}
                      {/* <Checkbox
                        onChange={(e) => {
                          product_list?.map((d) => {
                            const checkboxId = d?.id;
                            setLocalChecked((prev) => {
                              if (e?.target?.checked) {
                                return [...prev, { ...d }];
                              } else {
                                return prev.filter(
                                  (prod) => prod?.id !== checkboxId
                                );
                              }
                            });
                          });
                        }}
                      /> */}
                    </div>
                    <div className="text-end">
                      <button
                        className="btn btn-light-primary btn-sm ms-2"
                        disabled={
                          product_list?.length == selected_product_list?.length
                        }
                        onClick={() => {
                          // product_list?.map((d) => {
                          //   set_selected_product_list([
                          //     ...selected_product_list,
                          //     { ...d },
                          //   ]);
                          // });
                          set_selected_product_list(
                            product_list?.map((d) => {
                              return { ...d, state: "ENABLED" };
                            })
                          );
                        }}
                      >
                        Add all
                      </button>
                    </div>
                    <div
                      className="mt-5"
                      style={{ height: "318px", overflow: "scroll" }}
                    >
                      {/* {console.log(
                        localChecked,
                        "-product_listproduct_listproduct_listproduct_list"
                      )} */}
                      {product_loading ? (
                        <>
                          <Skeleton.Button active block className="my-1" />
                          <Skeleton.Button active block className="my-1" />
                          <Skeleton.Button active block className="my-1" />
                          <Skeleton.Button active block className="my-1" />
                          <Skeleton.Button active block className="my-1" />
                        </>
                      ) : product_list?.length > 0 ? (
                        <div>
                          {product_list?.map((d, i) => {
                            const isProductAdded =
                              selected_product_list?.filter(
                                (f) => f?.asin1 === d?.asin1
                              );
                            return (
                              <div className="rounded bg-light p-2 mb-3">
                                <div className="d-flex align-items-center">
                                  {/* {isProductAdded?.length == 0 && (
                                <Checkbox
                                  checked={
                                    localChecked.filter((a) => a?.id == d?.id)
                                      ?.length == 1
                                      ? true
                                      : false
                                  }
                                  onChange={(e) => {
                                    const checkboxId = d?.id;
                                    setLocalChecked((prev) => {
                                      if (e?.target?.checked) {
                                        return [
                                          ...prev,
                                          { ...d, state: "ENABLED" },
                                        ];
                                      } else {
                                        return prev.filter(
                                          (prod) => prod?.id !== checkboxId
                                        );
                                      }
                                    });
                                  }}
                                />
                              )} */}
                                  <div className="symbol symbol-45px overflow-hidden me-3 shadow-sm">
                                    <img src={d?.image_url} className alt />
                                  </div>
                                  <div className="d-flex flex-column me-2 fs-7">
                                    <div className="d-flex">
                                      <Popover
                                        content={
                                          <div
                                            style={{
                                              height: "max-content",
                                              maxWidth: "400px",
                                            }}
                                          >
                                            {d?.item_name}
                                          </div>
                                        }
                                        placement="bottom"
                                      >
                                        <span className="text-gray-800 mb-1 popoverActionIconSingleLine fw-bolder fs-7">
                                          {d?.item_name}
                                        </span>
                                      </Popover>
                                    </div>
                                    <span className="text-gray-700 fs-8">
                                      ASIN:{" "}
                                      <b className="text-primary">{d?.asin1}</b>{" "}
                                      / SKU:{" "}
                                      <b className="text-primary">
                                        {" "}
                                        {d?.seller_sku}
                                      </b>
                                    </span>
                                  </div>
                                  <div>
                                    {isProductAdded?.length == 0 && (
                                      <span
                                        className="btn btn-light bg-white btn-sm btn-icon"
                                        onClick={() => {
                                          set_selected_product_list([
                                            ...selected_product_list,
                                            { ...d, state: "ENABLED" },
                                          ]);
                                        }}
                                      >
                                        <i className="ki-outline ki-plus fs-3 text-danger" />
                                      </span>
                                    )}
                                  </div>
                                </div>
                              </div>
                            );
                          })}

                          <Pagination
                            loading={
                              product_loading || product_list?.length === 0
                            }
                            pageSize={all_filters?.["per-page"]}
                            page={all_filters?.offset}
                            totalPage={totalPage}
                            onPerPage={onPerPage}
                            onPageNo={onPageNo}
                          />
                        </div>
                      ) : (
                        "No Data"
                      )}
                    </div>
                  </div>
                  <div
                    className={`tab-pane fade  ${
                      all_common_hide_show?.add_items === "enter_list"
                        ? "show active"
                        : ""
                    }`}
                  >
                    <div className>
                      <Input.TextArea
                        onPressEnter={(e) => {
                          const finalAsin = e?.target.value?.split(",");
                          const data = {
                            marketplace_id: selected_marketplace,
                            product_asins: selected_product_list?.map((d) => {
                              return d?.asin1;
                            }),
                            asins: finalAsin.map((str) => str.trim()),
                          };
                          setLoading(true);
                          GetEnterListProducts(data);
                        }}
                        onChange={(e) => {
                          set_enter_list_val(e.target.value);
                        }}
                        value={enter_list_val}
                        cols={30}
                        rows={10}
                        placeholder="Enter ASINs seprated by comma, space or new line."
                      />
                      <div className="mt-3 text-end">
                        <button
                          className="btn btn-dark fs-7"
                          disabled={loading}
                          onClick={() => {
                            const finalAsin = enter_list_val?.split(",");
                            const data = {
                              marketplace_id: selected_marketplace,
                              product_asins: selected_product_list?.map((d) => {
                                return d?.asin1;
                              }),
                              asins: finalAsin.map((str) => str.trim()),
                            };
                            setLoading(true);
                            GetEnterListProducts(data);
                          }}
                        >
                          {loading && (
                            <span className="spinner-border spinner-border-sm align-middle ms-2" />
                          )}{" "}
                          Add
                        </button>
                      </div>
                      {inValidAsin?.length > 0 && (
                        <div className="row px-0 mx-0">
                          <div className="col-12 px-0 mx-0">
                            <label className="text-danger fw-bolder fs-6">
                              {inValidAsin?.length}&nbsp; Producs weren't added
                              <span
                                className="text-info cursor-pointer"
                                onClick={() => {
                                  setIsOpenErrorModal(true);
                                }}
                              >
                                {" "}
                                Click here to view{" "}
                              </span>
                            </label>
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                  <div
                    className={`tab-pane fade  ${
                      all_common_hide_show?.add_items === "upload"
                        ? "show active"
                        : ""
                    }`}
                  >
                    <p className="mb-3 mt-2 text-muted">
                      Use the template to make sure your information uploads
                      accurately.
                    </p>
                    <div className="mb-5">
                      <span
                        className="fw-bold text-primary cursor-pointer"
                        onClick={() => {
                          downloadFile(
                            "/assets/media/sample-template/",
                            "product_template.xlsx"
                          );
                        }}
                      >
                        <i className="bi bi-download text-primary me-3" />
                        Download the sample template
                      </span>
                    </div>

                    <div className="fv-row">
                      <div>
                        <Dragger
                          customRequest={customUpload}
                          beforeUpload={beforeUpload}
                          // className="dropzone"
                          // id="kt_dropzonejs_example_1"
                          // style={{ padding: "2px" }}
                        >
                          <div className="dz-message needsclick">
                            {isUploading ? (
                              <LoadingOutlined
                                style={{ color: "#008000", fontSize: "28px" }}
                              />
                            ) : (
                              <i className="ki-duotone ki-file-up fs-3x text-primary">
                                <span className="path1" />
                                <span className="path2" />
                              </i>
                            )}

                            <div className="ms-4">
                              <h3 className="fs-5 fw-bold text-gray-900 mb-1">
                                Drop files here or click to upload.
                              </h3>
                              <span className="fs-7 fw-semibold text-gray-600">
                                Accepted formats: CSV, XLSX
                              </span>
                            </div>
                          </div>
                          {/* <div className="d-flex align-items-center justify-content-between">
                            <div className="text-start">
                              <p className="ant-upload-text fs-4 fw-bolder mb-0">
                                Drop files here or click to upload xlsx.
                              </p>
                            </div>
                            {isUploading ? (
                              <LoadingOutlined
                                style={{ color: "#008000", fontSize: "28px" }}
                              />
                            ) : (
                              <i className="bi bi-file-earmark-arrow-up fs-3x" />
                            )}
                          </div> */}
                        </Dragger>
                      </div>
                    </div>

                    {inValidAsin?.length > 0 && (
                      <div className="row px-0 mx-0 mt-10">
                        <div className="col-12 px-0 mx-0">
                          <label className="text-danger fw-bolder fs-6">
                            {inValidAsin?.length}&nbsp; Producs weren't added
                            <span
                              className="text-info cursor-pointer"
                              onClick={() => {
                                setIsOpenErrorModal(true);
                              }}
                            >
                              {" "}
                              Click here to view{" "}
                            </span>
                          </label>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="col-xxl-6">
            <div className="card min-h-450px">
              <div className="card-header ">
                <div className="card-title m-0">
                  <h3 className="fw-bold m-0">
                    {selected_product_list?.length} Products
                  </h3>
                </div>
                <div className="card-toolbar">
                  <span
                    className="btn fs-7 btn-light-primary fw-bold btn-sm"
                    onClick={() => set_selected_product_list([])}
                  >
                    Remove All
                  </span>
                </div>
              </div>
              <div className="card-body">
                <div
                  className="mt-0"
                  style={{ height: "371px", overflow: "scroll" }}
                >
                  {selected_product_list?.map((d) => {
                    return (
                      <div className="rounded bg-light p-3 mb-4">
                        <div className="d-flex align-items-center">
                          <div className="symbol symbol-45px overflow-hidden me-3 shadow-sm">
                            <img src={d?.image_url} className alt />
                          </div>
                          <div className="d-flex flex-column">
                            <div className="d-flex">
                              <Popover
                                content={
                                  <div
                                    style={{
                                      height: "max-content",
                                      maxWidth: "400px",
                                    }}
                                  >
                                    {d?.item_name}
                                  </div>
                                }
                                placement="bottom"
                              >
                                <span className="text-gray-800 mb-1 popoverActionIconSingleLine fw-bolder fs-7">
                                  {d?.item_name}
                                </span>
                              </Popover>
                            </div>
                            <span
                              className="text-gray-700 fs-8"
                              // style={{ fontSize: "11px !important" }}
                            >
                              <Switch
                                size="small"
                                checked={d?.state === "ENABLED" ? true : false}
                                onChange={(e) => {
                                  set_selected_product_list((prev) => {
                                    return prev?.map((a) => {
                                      if (a?.asin1 === d?.asin1) {
                                        return {
                                          ...d,
                                          state: e ? "ENABLED" : "DISABLED",
                                        };
                                      } else {
                                        return a;
                                      }
                                    });
                                  });
                                }}
                              />{" "}
                              ASIN: <b className="text-primary"> {d?.asin1}</b>{" "}
                              / SKU:{" "}
                              <b className="text-primary">{d?.seller_sku}</b>
                            </span>
                          </div>
                          <div>
                            <span
                              className="btn btn-light bg-white btn-sm btn-icon"
                              onClick={() => {
                                const isDeleted = selected_product_list?.filter(
                                  (f) => f?.asin1 !== d?.asin1
                                );
                                set_selected_product_list(isDeleted);
                              }}
                            >
                              <i className="ki-outline ki-trash fs-3 text-danger" />
                            </span>
                          </div>
                        </div>
                      </div>
                    );
                  })}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {isOpenErrorModal && (
        <ErrorModal
          show={isOpenErrorModal}
          inValidAsin={inValidAsin}
          onHide={() => {
            setIsOpenErrorModal(false);
          }}
        />
      )}
    </>
  );
};

export default SPStep2;
