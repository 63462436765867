import { Modal } from "react-bootstrap";
import React, { useState } from "react";
import { message, Radio, Tag } from "antd";
import { DisplayNotification } from "../../../../../config";
import { MakeApiCall } from "../../../../../apis/make-api-call";

export default function (props) {
  const {
    show,
    onHide,
    operationType,
    selectedCampaign,
    setLoading,
    onSuccess,
  } = props;
  const [sending, setSending] = useState(false);
  const [selectedStatus, setSelectedStatus] = useState("PAUSED");

  const UpdateCampaignStatusAction = async (data) => {
    const response = await MakeApiCall(
      `advertising/update-bulk-campaign`,
      `put`,
      data,
      true
    );
    if (response?.status) {
      setLoading(true);
      setSending(false);
      onSuccess();
      DisplayNotification("success", "Success", response?.message);
    } else {
      setSending(false);
      DisplayNotification("error", "Error", response?.message);
    }
  };

  return (
    <Modal show={show} onHide={onHide} size="md" centered backdrop="static">
      <Modal.Header closeButton>
        <Modal.Title>Update Campaign</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div>
          {operationType === "status" && (
            <div className="row">
              <div className="col-12 mb-4">
                <label className="fw-bold fs-6">Campaign Status</label>
              </div>
              <div className="col-12 mb-7">
                <Radio.Group
                  name="radiogroup"
                  //   defaultValue={"PAUSED"}
                  value={selectedStatus}
                  onChange={(e) => {
                    setSelectedStatus(e.target.value);
                  }}
                >
                  <Radio value={"PAUSED"}>
                    <Tag className="fw-bolder" color={"orange"}>
                      PAUSED
                    </Tag>
                  </Radio>
                  <Radio value={"ENABLED"}>
                    <Tag className="fw-bolder" color={"green"}>
                      ENABLED
                    </Tag>
                  </Radio>
                </Radio.Group>
              </div>
            </div>
          )}
        </div>
      </Modal.Body>
      <Modal.Footer>
        <button
          type="button"
          class="btn btn-light-danger fs-7"
          onClick={onHide}
        >
          Close
        </button>
        <button
          type="button"
          class="btn btn-primary fs-7"
          disabled={sending}
          onClick={() => {
            setSending(true);
            const data = {
              campaign_id: selectedCampaign,
              state: selectedStatus,
            };
            UpdateCampaignStatusAction(data);
          }}
        >
          Update
          {sending && (
            <span className="spinner-border spinner-border-sm align-middle ms-2" />
          )}
        </button>
      </Modal.Footer>
    </Modal>
  );
}
