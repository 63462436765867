import { Popover } from "antd";
import { NumberWithCommas } from "../../../../../config";

export const GetColumns = (type, all_filters) => {
  if (type === "out_of_stock") {
    return [
      {
        title: "#",
        width: 20,
        fixed: "left",
        render: (text) => (
          <span>
            {(all_filters?.page - 1) * all_filters?.["per-page"] +
              1 +
              text?.key}
          </span>
        ),
      },
      {
        title: "Product",
        dataIndex: "item_name",
        key: "item_name",
        render: (text, record) => (
          <div className="d-flex align-items-center border border-dashed border-gray-300 p-3 rounded bdr-left-user1">
            <div className="symbol symbol-50px overflow-hidden me-3 shadow-sm">
              <img src={record?.image_url} />
            </div>
            <div className="d-flex flex-column">
              <Popover
                getPopupContainer={(triggerNode) => triggerNode.parentNode}
                content={
                  <div
                    style={{
                      height: "max-content",
                      maxWidth: "400px",
                    }}
                  >
                    {record?.item_name}
                  </div>
                }
                placement="bottom"
              >
                <span
                  className="text-dark mb-1 fw-bolder popoverActionIconSingleLine fs-6"
                  style={{ width: "300px" }}
                >
                  {record?.item_name}
                </span>
              </Popover>

              <div>
                <span className="fs-7">
                  <b>ASIN:</b>{" "}
                  <span className="text-primary">{record?.asin1}</span>
                </span>{" "}
                |
                <span className="fs-7">
                  <b>SKU:</b>{" "}
                  <span className="text-primary">{record?.seller_sku}</span>
                </span>
              </div>
            </div>
          </div>
        ),
        width: 300,
      },
      {
        title: "Brand Name",
        dataIndex: "brand_name",
        key: "brand_name",
      },
      { title: "Status", dataIndex: "status", key: "status" },
      { title: "Listing ID", dataIndex: "listing_id", key: "listing_id" },
      { title: "Seller SKU", dataIndex: "seller_sku", key: "seller_sku" },
      {
        title: "Price",
        dataIndex: "price",
        key: "price",
        render: (price) => <label>${NumberWithCommas(price)}</label>,
      },
      {
        title: "New Price",
        dataIndex: "new_price",
        key: "new_price",
        render: (price) => <label>${NumberWithCommas(price)}</label>,
      },
      {
        title: "Stock Quantity",
        dataIndex: "stock_quantity",
        key: "stock_quantity",
      },
      {
        title: "Ineligible Status",
        dataIndex: "ineligible_status",
        key: "ineligible_status",
      },
      {
        title: "Fulfillment Channel",
        dataIndex: "fulfillment_channel",
        key: "fulfillment_channel",
      },
      { title: "Source", dataIndex: "source", key: "source" },
    ];
  } else if (type === "ineligible_ads") {
    return [
      {
        title: "#",
        width: 20,
        fixed: "left",
        render: (text) => (
          <span>
            {(all_filters?.page - 1) * all_filters?.["per-page"] +
              1 +
              text?.key}
          </span>
        ),
      },
      {
        title: "Product",
        dataIndex: "item_name",
        key: "item_name",
        render: (text, record) => (
          <div className="d-flex align-items-center border border-dashed border-gray-300 p-3 rounded bdr-left-user1">
            <div className="symbol symbol-50px overflow-hidden me-3 shadow-sm">
              <img src={record?.image_url} />
            </div>
            <div className="d-flex flex-column">
              <Popover
                getPopupContainer={(triggerNode) => triggerNode.parentNode}
                content={
                  <div
                    style={{
                      height: "max-content",
                      maxWidth: "400px",
                    }}
                  >
                    {record?.item_name}
                  </div>
                }
                placement="bottom"
              >
                <span
                  className="text-dark mb-1 fw-bolder popoverActionIconSingleLine fs-6"
                  style={{ width: "300px" }}
                >
                  {record?.item_name}
                </span>
              </Popover>

              <div>
                <span className="fs-7">
                  <b>ASIN:</b>{" "}
                  <span className="text-primary">{record?.asin1}</span>
                </span>{" "}
                |
                <span className="fs-7">
                  <b>SKU:</b>{" "}
                  <span className="text-primary">{record?.seller_sku}</span>
                </span>
              </div>
            </div>
          </div>
        ),
        width: 300,
      },
      {
        title: "Brand Name",
        dataIndex: "brand_name",
        key: "brand_name",
      },
      {
        title: "Status",
        dataIndex: "status",
        key: "status",
      },
      {
        title: "Listing ID",
        dataIndex: "listing_id",
        key: "listing_id",
      },
      {
        title: "Seller SKU",
        dataIndex: "seller_sku",
        key: "seller_sku",
      },
      {
        title: "Price",
        dataIndex: "price",
        key: "price",
        render: (price) => `$${price}`,
      },
      {
        title: "Stock Quantity",
        dataIndex: "stock_quantity",
        key: "stock_quantity",
      },
      {
        title: "Ineligible Status",
        dataIndex: "ineligible_status",
        key: "ineligible_status",
      },

      {
        title: "Product ID",
        dataIndex: "product_id",
        key: "product_id",
      },
      {
        title: "Fulfillment Channel",
        dataIndex: "fulfillment_channel",
        key: "fulfillment_channel",
      },
      {
        title: "Source",
        dataIndex: "source",
        key: "source",
      },
    ];
  } else if (type === "payment_billing_notifications") {
    return [
      {
        title: "#",
        width: 20,
        fixed: "left",
        render: (text) => (
          <span>
            {(all_filters?.page - 1) * all_filters?.["per-page"] +
              1 +
              text?.key}
          </span>
        ),
      },
      {
        title: "Severity",
        dataIndex: "severity",
        key: "severity",
      },
      {
        title: "Payment Due Date",
        dataIndex: "payment_due_date",
        key: "payment_due_date",
      },
      {
        title: "Suspension Date",
        dataIndex: "suspension_date",
        key: "suspension_date",
      },
      {
        title: "Description",
        dataIndex: "description",
        key: "description",
      },
      {
        title: "Priority",
        dataIndex: "priority",
        key: "priority",
      },
      {
        title: "Title",
        dataIndex: "title",
        key: "title",
      },
      {
        title: "Notification Name",
        dataIndex: "notification_name",
        key: "notification_name",
      },

      {
        title: "Advertiser Type",
        dataIndex: "advertiser_type",
        key: "advertiser_type",
      },
      {
        title: "Advertiser ID",
        dataIndex: "advertiser_id",
        key: "advertiser_id",
      },
      {
        title: "Billing Status Code",
        dataIndex: "billing_status_code",
        key: "billing_status_code",
      },
    ];
  } else if (type === "negative_seller_feedbacks") {
    return [
      {
        title: "#",
        width: 20,
        fixed: "left",
        render: (text) => (
          <span>
            {(all_filters?.page - 1) * all_filters?.["per-page"] +
              1 +
              text?.key}
          </span>
        ),
      },
      {
        title: "Feedback Date",
        dataIndex: "feedback_date",
        key: "feedback_date",
      },
      {
        title: "Status",
        dataIndex: "status",
        key: "status",
      },
      {
        title: "Rating",
        dataIndex: "rating",
        key: "rating",
      },
      {
        title: "Comments",
        dataIndex: "comments",
        key: "comments",
      },
      {
        title: "Response",
        dataIndex: "response",
        key: "response",
      },
      {
        title: "Order ID",
        dataIndex: "order_id",
        key: "order_id",
      },
      {
        title: "Rater Email",
        dataIndex: "rater_email",
        key: "rater_email",
      },
    ];
  } else if (type === "sales_price_changes") {
    return [
      {
        title: "#",
        width: 20,
        fixed: "left",
        render: (text) => (
          <span>
            {(all_filters?.page - 1) * all_filters?.["per-page"] +
              1 +
              text?.key}
          </span>
        ),
      },
      {
        title: "Product",
        dataIndex: "item_name",
        key: "item_name",
        render: (text, record) => (
          <div className="d-flex align-items-center border border-dashed border-gray-300 p-3 rounded bdr-left-user1">
            <div className="symbol symbol-50px overflow-hidden me-3 shadow-sm">
              <img src={record?.image_url} />
            </div>
            <div className="d-flex flex-column">
              <Popover
                getPopupContainer={(triggerNode) => triggerNode.parentNode}
                content={
                  <div
                    style={{
                      height: "max-content",
                      maxWidth: "400px",
                    }}
                  >
                    {record?.item_name}
                  </div>
                }
                placement="bottom"
              >
                <span
                  className="text-dark mb-1 fw-bolder popoverActionIconSingleLine fs-6"
                  style={{ width: "300px" }}
                >
                  {record?.item_name}
                </span>
              </Popover>

              <div>
                <span className="fs-7">
                  <b>ASIN:</b>{" "}
                  <span className="text-primary">{record?.asin1}</span>
                </span>{" "}
                |
                <span className="fs-7">
                  <b>SKU:</b>{" "}
                  <span className="text-primary">{record?.seller_sku}</span>
                </span>
              </div>
            </div>
          </div>
        ),
        width: 300,
      },
      {
        title: "Status",
        dataIndex: "status",
        key: "status",
      },
      {
        title: "Listing ID",
        dataIndex: "listing_id",
        key: "listing_id",
      },
      {
        title: "Seller SKU",
        dataIndex: "seller_sku",
        key: "seller_sku",
      },
      {
        title: "Old Price",
        dataIndex: "price",
        key: "price",
        render: (price) => (price > 0 ? `$${price}` : "N/A"),
      },
      {
        title: "New Price",
        dataIndex: "new_price",
        key: "new_price",
        render: (new_price) => `$${new_price}`,
      },
      {
        title: "Stock Quantity",
        dataIndex: "stock_quantity",
        key: "stock_quantity",
      },
      {
        title: "Ineligible Status",
        dataIndex: "ineligible_status",
        key: "ineligible_status",
      },
      {
        title: "Product ID",
        dataIndex: "product_id",
        key: "product_id",
      },
      {
        title: "Fulfillment Channel",
        dataIndex: "fulfillment_channel",
        key: "fulfillment_channel",
      },
      {
        title: "Source",
        dataIndex: "source",
        key: "source",
      },
    ];
  } else if (type === "buyBox_affected_products") {
    return [
      {
        title: "#",
        width: 20,
        fixed: "left",
        render: (text) => (
          <span>
            {(all_filters?.page - 1) * all_filters?.["per-page"] +
              1 +
              text?.key}
          </span>
        ),
      },

      {
        title: "SKU",
        dataIndex: "sku",
        key: "sku",
      },
      {
        title: "ASIN",
        dataIndex: "asin",
        key: "asin",
      },
      {
        title: "Seller ID",
        dataIndex: "seller_id",
        key: "seller_id",
      },
      {
        title: "Buy Box Price",
        dataIndex: "buy_box_price",
        key: "buy_box_price",
      },
      {
        title: "Buy Box Status",
        dataIndex: "buy_box_status",
        key: "buy_box_status",
        render: (text) => (text === "True" ? "Yes" : "No"),
      },
      {
        title: "Reflected Date",
        dataIndex: "reflected_date",
        key: "reflected_date",
      },
    ];
  } else if (type === "acos_increasing_by_higher_margin") {
    return [
      {
        title: "#",
        width: 20,
        fixed: "left",
        render: (text) => (
          <span>
            {(all_filters?.page - 1) * all_filters?.["per-page"] +
              1 +
              text?.key}
          </span>
        ),
      },
      // {
      //   title: "Date",
      //   dataIndex: "date",
      //   key: "date",
      //   width: 100,
      // },
      {
        title: "Advertising Type",
        dataIndex: "advertising_type",
        key: "advertising_type",
      },
      {
        title: "Previous Start Date",
        dataIndex: "previous_start_date",
        key: "previous_start_date",
      },
      {
        title: "Previous End Date",
        dataIndex: "previous_end_date",
        key: "previous_end_date",
      },
      {
        title: "Previous Cost",
        dataIndex: "previous_cost",
        key: "previous_cost",
        render: (cost) => `$${cost.toFixed(2)}`,
      },
      {
        title: "Previous Sales",
        dataIndex: "previous_sales",
        key: "previous_sales",
      },
      {
        title: "Previous ACOS",
        dataIndex: "previous_acos",
        key: "previous_acos",
        render: (acos) => `${acos.toFixed(2)}%`,
      },
      {
        title: "Current Start Date",
        dataIndex: "current_start_date",
        key: "current_start_date",
      },
      {
        title: "Current End Date",
        dataIndex: "current_end_date",
        key: "current_end_date",
      },
      {
        title: "Current Cost",
        dataIndex: "current_cost",
        key: "current_cost",
        render: (cost) => `$${cost.toFixed(2)}`,
      },
      {
        title: "Current Sales",
        dataIndex: "current_sales",
        key: "current_sales",
      },
      {
        title: "Current ACOS",
        dataIndex: "current_acos",
        key: "current_acos",
        render: (acos) => `${acos.toFixed(2)}%`,
      },
    ];
  } else if (type === "product_issue_duration") {
    return [
      {
        title: "#",
        width: 20,
        fixed: "left",
        render: (text) => (
          <span>
            {(all_filters?.page - 1) * all_filters?.["per-page"] +
              1 +
              text?.key}
          </span>
        ),
      },
      {
        title: "Product",
        dataIndex: "item_name",
        key: "item_name",
        render: (text, record) => (
          <div className="d-flex align-items-center border border-dashed border-gray-300 p-3 rounded bdr-left-user1">
            <div className="symbol symbol-50px overflow-hidden me-3 shadow-sm">
              <img src={record?.image_url} />
            </div>
            <div className="d-flex flex-column">
              <Popover
                getPopupContainer={(triggerNode) => triggerNode.parentNode}
                content={
                  <div
                    style={{
                      height: "max-content",
                      maxWidth: "400px",
                    }}
                  >
                    {record?.item_name}
                  </div>
                }
                placement="bottom"
              >
                <span
                  className="text-dark mb-1 fw-bolder popoverActionIconSingleLine fs-6"
                  style={{ width: "300px" }}
                >
                  {record?.item_name}
                </span>
              </Popover>

              <div>
                <span className="fs-7">
                  <b>ASIN:</b>{" "}
                  <span className="text-primary">{record?.asin1}</span>
                </span>{" "}
                |
                <span className="fs-7">
                  <b>SKU:</b>{" "}
                  <span className="text-primary">{record?.seller_sku}</span>
                </span>
              </div>
            </div>
          </div>
        ),
        width: 300,
      },
      {
        title: "Brand Name",
        dataIndex: "brand_name",
        key: "brand_name",
      },
      { title: "Status", dataIndex: "status", key: "status" },
      { title: "Listing ID", dataIndex: "listing_id", key: "listing_id" },
      { title: "Seller SKU", dataIndex: "seller_sku", key: "seller_sku" },
      {
        title: "Price",
        dataIndex: "price",
        key: "price",
        render: (price) => <label>${NumberWithCommas(price)}</label>,
      },
    ];
  }
};
