import { Checkbox, Input, Radio, Upload, message } from "antd";
import React, { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import { MakeApiCall } from "../../../../../apis/make-api-call";
import { DisplayNotification, PhotoUrl } from "../../../../../config";
import { AddUserWrapper } from "../style";
export default function ({ show, onHide, data }) {
  const [sending, setSending] = useState(false);
  const [userId, setUserId] = useState("");
  const [password, set_password] = useState("");
  const [confirm_pass, set_confirm_pass] = useState("");
  const [fileList, setFileList] = useState([]);
  const [userData, setUserData] = useState({
    name: "",
    email: "",
    contact_no: "",
    address: "",
    user_type: "3",
  });

  useEffect(() => {
    if (data?.id) {
      setUserId(data?.id);
      setUserData({
        name: data?.name,
        email: data?.email,
        contact_no: data?.contact_no,
        address: data?.address,
      });
    }
  }, [data]);

  const onSubmitUser = async () => {
    message.destroy();
    message.loading("Loading", 0);
    const body = {
      ...userData,
      password: password,
    };
    if (userId) {
      delete body.password;
    }
    console.log(userData, body, "-------userData");
    let formData = new FormData();
    formData.append("address", body?.address);
    formData.append("contact_no", body?.contact_no);
    formData.append("name", body?.name);
    if (fileList?.[0]?.originFileObj) {
      formData.append("photo", fileList?.[0]?.originFileObj);
    }
    if (!userId) {
      formData.append("email", body?.email);
      formData.append("password", body?.password);
    }
    const response = await MakeApiCall(
      userId ? `user/${userId}` : "user",
      "post",
      formData,
      true,
      true
    );
    if (response?.status) {
      setSending(false);
      message.destroy();
      DisplayNotification("success", "Success", response?.message);
      onHide();
    } else {
      message.destroy();
      DisplayNotification("error", "Error", response?.message);
      setSending(false);
    }
  };

  const setUserDataValue = (key, value) => {
    const common_data = {
      ...userData,
      [key]: value,
    };
    setUserData(common_data);
  };

  const handleChange = ({ fileList: newFileList }) => setFileList(newFileList);
  console.log(fileList, "-fileList");
  return (
    <>
      <Modal show={show} onHide={onHide} size="md" centered backdrop="static">
        <Modal.Header closeButton>
          <Modal.Title>{userId ? "Update " : "Add "} User</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <AddUserWrapper>
            <div style={{ minHeight: "300px" }}>
              <div>
                <div className="row mb-6 mt-4 align-items-center">
                  {/*begin::Label*/}
                  <label className="col-lg-4 fs-6 fw-bold mb-2">Avatar</label>
                  {/*end::Label*/}
                  {/*begin::Col*/}
                  <div className="col-lg-8">
                    {/*begin::Image input*/}
                    <div className="image-input image-input-outline">
                      <Upload
                        listType="picture-card"
                        fileList={fileList}
                        maxCount="1"
                        accept=".png, .jpg, .jpeg"
                        onChange={(e) => {
                          handleChange(e);
                        }}
                      >
                        {fileList.length == 1 ? null : (
                          <>
                            <div style={{ position: "relative" }}>
                              <button
                                className="btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-2"
                                style={{
                                  position: "absolute",
                                  top: "-35px",
                                  right: "-38px",
                                }}
                              >
                                <i className="ki-outline ki-pencil fs-2 text-primary" />
                              </button>
                              <div
                                style={{
                                  height: "60px",
                                  width: "60px",
                                  background: "#e6332a",
                                  borderRadius: "30px",
                                  color: "rgb(255, 255, 255)",
                                  boxShadow:
                                    "#a39797 0px -50px 36px -28px inset",
                                }}
                                className="d-flex justify-content-center align-items-center"
                              >
                                <img
                                  src="/assets/media/user.png"
                                  style={{ width: "45px" }}
                                />
                              </div>
                            </div>
                          </>
                        )}
                      </Upload>
                    </div>
                    {/*end::Image input*/}
                    {/*begin::Hint*/}
                    <div className="form-text">
                      Allowed file types: png, jpg, jpeg.
                    </div>
                    {/*end::Hint*/}
                  </div>
                  {/*end::Col*/}
                </div>

                <div className="row g-9 mb-5 align-items-center">
                  <div className="col-md-6 fv-row">
                    <label className="fs-6 fw-bold mb-2 required">Name</label>
                    <Input
                      type="text"
                      className="ant_common_input"
                      size="large"
                      value={userData?.name}
                      onChange={(e) => {
                        setUserDataValue("name", e.target.value);
                      }}
                    />
                  </div>
                  <div className="col-md-6 fv-row">
                    <label className="fs-6 fw-bold mb-2 required">Email</label>
                    <Input
                      type="text"
                      className="ant_common_input"
                      size="large"
                      disabled={userId}
                      value={userData?.email}
                      onChange={(e) => {
                        !userId && setUserDataValue("email", e.target.value);
                      }}
                    />
                  </div>
                </div>
                <div className="row g-9 mb-5 align-items-center">
                  <div className="col-md-6 fv-row">
                    <label className="fs-6 fw-bold mb-2 required">
                      Contact No
                    </label>
                    <Input
                      type="text"
                      className="ant_common_input"
                      size="large"
                      value={userData?.contact_no}
                      onChange={(e) => {
                        setUserDataValue("contact_no", e.target.value);
                      }}
                    />
                  </div>
                  <div className="col-md-6 fv-row">
                    <label className="fs-6 fw-bold mb-2 required">
                      Address
                    </label>
                    <Input
                      type="text"
                      className="ant_common_input"
                      size="large"
                      autoComplete="new-address"
                      value={userData?.address}
                      onChange={(e) => {
                        setUserDataValue("address", e.target.value);
                      }}
                    />
                  </div>
                </div>
                {!userId && (
                  <div className="row g-9 mb-5 align-items-center">
                    <div className="col-md-6 fv-row">
                      <label className="fs-6 fw-bold mb-2 required">
                        Passowrd
                      </label>
                      <Input.Password
                        className="ant_common_input"
                        size="large"
                        autoComplete="new-password"
                        value={password}
                        onChange={(e) => {
                          set_password(e.target.value);
                        }}
                      />
                    </div>
                    <div className="col-md-6 fv-row">
                      <label className="fs-6 fw-bold mb-2 required">
                        Confirm Passowrd
                      </label>
                      <Input.Password
                        className="ant_common_input"
                        size="large"
                        value={confirm_pass}
                        onChange={(e) => {
                          set_confirm_pass(e.target.value);
                        }}
                        onBlur={(e) => {
                          if (password !== e.target.value) {
                            set_confirm_pass("");
                            message.destroy();
                            DisplayNotification(
                              "info",
                              "Info",
                              "Password and Confirm password not matched"
                            );
                          }
                        }}
                      />
                    </div>
                  </div>
                )}
                <div className="row mt-4">
                  <div className="col-md-12 fv-row">
                    <label className="fs-6 fw-bold mb-2 required me-4">
                      User Type
                    </label>
                    <Radio.Group
                      onChange={(e) => {
                        setUserDataValue("user_type", e.target.value);
                      }}
                      value={userData?.user_type}
                    >
                      <Radio value={"3"}>Seller</Radio>
                      <Radio value={"2"}>User</Radio>
                    </Radio.Group>
                  </div>
                </div>
              </div>
            </div>
          </AddUserWrapper>
        </Modal.Body>
        <Modal.Footer>
          <button
            type="button"
            class="btn btn-light-danger fs-7"
            onClick={onHide}
          >
            Close
          </button>
          <button
            type="button"
            class="btn btn-primary fs-7"
            onClick={() => {
              if (password !== confirm_pass) {
                message.destroy();
                message.warning("Password and Confirm password not matched");
              } else {
                setSending(true);
                // console.log({
                //   ...userData,
                //   selected: userData?.platforms?.[0],
                // });
                onSubmitUser();
              }
            }}
            disabled={
              sending ||
              fileList?.length == 0 ||
              !userData?.name ||
              !userData?.contact_no ||
              !userData?.email ||
              !userData?.address
            }
          >
            Submit
            {sending && (
              <span className="spinner-border spinner-border-sm align-middle ms-2" />
            )}
          </button>
        </Modal.Footer>
      </Modal>
    </>
  );
}
