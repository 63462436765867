import dayjs from "dayjs";
import React, { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import { MakeApiCall } from "../../../../apis/make-api-call";
import EsfTable from "../../../../component/esf-table";
import Pagination from "../../../../component/esf-table/lib/pagination";
import NoDataTable from "../../../../component/no-data-table";
import {
  ConvertParams,
  DefaultPerPage,
  DisplayNotification,
} from "../../../../config";

export default function (props) {
  const { show, onHide, selectedActionLog, selectedMarketplace } = props;

  const [logList, setLogList] = useState([]);
  const [loading, setLoading] = useState(true);
  const [totalPage, setTotalPage] = useState(1);
  const [all_filters, set_all_filters] = useState({
    offset: 1,
    "per-page": DefaultPerPage,
    master_rule_id: selectedActionLog?.id,
    marketplace_id: selectedMarketplace,
  });

  useEffect(() => {
    GetActionLogList(all_filters);
  }, []);

  const GetActionLogList = async (filters) => {
    const response = await MakeApiCall(
      `rule-management/get-applied-rule-logs${ConvertParams(filters)}`,
      `get`,
      null,
      true
    );
    if (response?.status) {
      setLoading(false);
      setLogList(response?.data?.records);
      setTotalPage(response?.data?.pagination?.totalCount);
    } else {
      setLoading(false);
      DisplayNotification("error", "Error", response?.message);
    }
  };

  const onPageNo = (e) => {
    set_all_filters({ ...all_filters, offset: e });
    setLoading(true);
    const apiObj = {
      ...all_filters,
      offset: e,
      "per-page": all_filters?.["per-page"],
    };
    GetActionLogList(apiObj);
  };

  const onPerPage = (e) => {
    set_all_filters({ ...all_filters, offset: 1, "per-page": e });
    setLoading(true);
    const apiObj = {
      ...all_filters,
      offset: 1,
      "per-page": e,
    };
    GetActionLogList(apiObj);
  };

  const columns = [
    {
      title: "#",
      width: 80,
      fixed: "left",
      render: (text, __, i) => {
        return (
          <div className="d-flex align-items-center justify-content-between">
            <span>
              {(all_filters?.offset - 1) * all_filters?.["per-page"] + 1 + i}
            </span>
            <span className="ms-5">
              {text?.new_value > text?.old_value ? (
                <label className="fw-bolder">
                  <i
                    class="ki-duotone ki-double-up fs-1"
                    style={{ color: "green" }}
                  >
                    <span class="path1"></span>
                    <span class="path2"></span>
                    <span class="path3"></span>
                  </i>
                </label>
              ) : (
                <label className="fw-bolder">
                  <i
                    class="ki-duotone ki-double-down fs-1"
                    style={{ color: "red" }}
                  >
                    <span class="path1"></span>
                    <span class="path2"></span>
                    <span class="path3"></span>
                  </i>
                </label>
              )}
            </span>
          </div>
        );
      },
    },
    {
      title: "Date",
      dataIndex: "date",
      key: "date",
    },
    // {
    //   title: "Target Type",
    //   dataIndex: "target_type",
    //   key: "target_type",
    // },
    {
      title: "Change",
      dataIndex: "change_type",
      key: "change_type",
    },
    {
      title: "Campaign",
      dataIndex: "campaign_name",
      key: "campaign_name",
    },
    {
      title: "AdGroup",
      dataIndex: "ad_group_name",
      key: "ad_group_name",
      width: 100,
    },
    {
      title: "Rule",
      dataIndex: "rule_name",
      key: "rule_name",
    },
    {
      title: "Target Name",
      dataIndex: "targeting_text",
      key: "targeting_text",
      width: 200,
      render: (text) => {
        return (
          <label className="fw-bolder" style={{ textTransform: "capitalize" }}>
            {text}
          </label>
        );
      },
    },
    {
      title: "Rule Applied Time",
      width: 200,
      render: (text) => {
        if (!text?.rule_applied_time) {
          return "-";
        }
        return (
          <span>
            {dayjs(parseInt(text?.rule_applied_time) * 1000).format(
              "MMM DD, YYYY"
            )}
          </span>
        );
      },
    },
    {
      title: "Old Value",
      dataIndex: "old_value",
      key: "old_value",
      width: 100,
      render: (text) => {
        return <span>${text}</span>;
      },
    },
    {
      title: "New Value",
      dataIndex: "new_value",
      key: "new_value",
      width: 120,
      render: (text) => {
        return <span>${text}</span>;
      },
    },
    {
      title: "Remarks",
      dataIndex: "remark",
      key: "remark",
      width: 300,
    },
  ];

  return (
    <>
      <Modal
        show={show}
        onHide={() => {
          onHide();
        }}
        size="xl"
        centered
        backdrop="static"
      >
        <Modal.Header closeButton>
          <Modal.Title>Action Logs - {selectedActionLog?.name}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <>
            <div className="row">
              <div className="table-responsive">
                {loading ? (
                  <NoDataTable columns={columns} type="loading" />
                ) : logList?.length > 0 ? (
                  <EsfTable
                    columns={columns?.map((d) => ({
                      ...d,
                    }))}
                    sticky={{
                      offsetHeader: "10px",
                    }}
                    scroll={{
                      x: "max-content",
                      y: "calc(100vh - 270px)",
                    }}
                    fixed={true}
                    dataSource={logList}
                    rowKey="key"
                    loading={loading}
                    pagination={false}
                  />
                ) : (
                  <>
                    <NoDataTable columns={columns} type="no-data" />
                  </>
                )}
              </div>
              <Pagination
                loading={loading || logList?.length === 0}
                pageSize={all_filters?.["per-page"]}
                page={all_filters?.offset}
                totalPage={totalPage}
                onPerPage={onPerPage}
                onPageNo={onPageNo}
              />
            </div>
            {/* <Pagination
              loading={loading || logList?.length === 0}
              pageSize={pageSize}
              page={page}
              totalPage={totalPage}
              onPerPage={onPerPage}
              onPageNo={onPageNo}
            /> */}
          </>
        </Modal.Body>
        <Modal.Footer>
          <div>
            <button
              className="btn btn-light-danger me-2"
              onClick={() => {
                onHide();
              }}
            >
              Cancel
            </button>
          </div>
        </Modal.Footer>
      </Modal>
    </>
  );
}
